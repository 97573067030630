import React from 'react';
import styles from './TextSourceCard.module.scss';

export type TextSourceDto = {
    showHeading: boolean;
    html: string;
    name?: string;
};

interface TextSourceCardProps {
    source: TextSourceDto;
}

const TextSourceCard: React.FC<TextSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.textCard}>
            <h2>{source.name || 'Text Information'}</h2>
            {source.showHeading && <h3>Text Content</h3>}
            <div dangerouslySetInnerHTML={{ __html: source.html }} />
        </div>
    );
};

export default TextSourceCard;
