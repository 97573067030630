import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement,
    registerables as registerablesJS,
} from 'chart.js';
import dayjs from 'dayjs';


ChartJS.register(ArcElement,Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            align: 'start' as const,
            position: 'top' as const,
            text: 'Build Performance',
            color: '#000000',
        },
    },
    maintainAspectRatio: false,
};



const PieChart = React.memo(
    ({
        chartRenderingData = {}
    }: any) => {
        const chartData = (data: BuildData) => {
            const labels = ['Builds Failed', 'Builds Successful', 'Builds Processing', 'Unlogged Builds'];
            const datasets = [{
                label: 'Build Status',
                data: [
                    data.buildsFailed,
                    data.buildsSuccessful,
                    data.buildsProcessing,
                    data.unloggedBuildSum,
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(130, 133, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(130, 133, 132, 1)'
                ],
                borderWidth: 1
            }];
            
            return { labels, datasets };
        };

        return <Doughnut data={chartData(chartRenderingData)} options={options} />;
    },
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

interface BuildData {
    buildsFailed: number;
    buildsSuccessful: number;
    buildsProcessing: number;
    unloggedBuildSum: number;
}

export default PieChart;
