import React from 'react';
import styles from './BuildSourceModal.module.scss';
import RSSSourceCard from './Sources/RSS/RSSSourceCard';
import WeatherSourceCard from './Sources/Weather/WeatherSourceCard';
import GoogleCalendarSourceCard from './Sources/Calender/GoogleCalendarSourceCard';
import HackerNewsSourceCard from './Sources/HackerNews/HackerNewsSourceCard';
import RedditSourceCard from './Sources/Reddit/RedditSourceCard';
import YoutubeSourceCard from './Sources/Youtube/YoutubeSourceCard';
import StripeSourceCard from './Sources/Stripe/StripeSourceCard';
import TwitterSearchSourceCard from './Sources/TwitterSearch/TwitterSearchSourceCard';
import TwitterTweetsSourceCard from './Sources/TwitterTweets/TwitterTweetsSourceCard';
import InstagramSourceCard from './Sources/Instagram/InstagramSourceCard';
import TiktokSourceCard from './Sources/TikTok/TiktokSourceCard';
import TextSourceCard from './Sources/Text/TextSourceCard';

interface ModalProps {
    source: any; 
    onClose: () => void; 
}


const Modal: React.FC<ModalProps> = ({ source, onClose }) => {

    const renderSourceCard = () => {
        switch (source.type) {
            case 'RSS':
                return <RSSSourceCard source={source} />;
            case 'HACKER_NEWS':
                return <HackerNewsSourceCard source={source} />;
            case 'GOOGLE_CALENDAR':
                return <GoogleCalendarSourceCard source={source} />;
            case 'WEATHER':
                return <WeatherSourceCard source={source} />;
            case 'REDDIT':
                return <RedditSourceCard source={source}/>
            case 'YOUTUBE':
                return <YoutubeSourceCard source={source}/>
            case 'PRODUCT_HUNT':
                return <YoutubeSourceCard source={source}/>
            case 'STRIPE':
                return <StripeSourceCard source={source}/>
            case 'TWITTER_SEARCH':
                return <TwitterSearchSourceCard source={source}/>
            case 'TWITTER_TWEETS':
                return <TwitterTweetsSourceCard source={source}/>
            case 'INSTAGRAM':
                return <InstagramSourceCard source={source}/>
            case 'TIKTOK':
                return <TiktokSourceCard source={source}/>
            case 'TEXT':
                return <TextSourceCard source={source}/>
            default:
                return <div>No component found for source type: {source.type}</div>;
        }
    };

    return (
        <div className={styles.modalBackdrop} onClick={onClose}>
            {renderSourceCard()}
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default Modal;