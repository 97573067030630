import React from 'react';
import styles from './TwitterTweetsSourceCard.module.scss';

export type TwitterUserDto = {
    twitterUserId: string;
    fullName: string;
    username: string;
    image?: string;
    followers: string;
    verified: boolean;
}

export type TwitterTweetsSourceDto = {
    numberOfTweetsPerUser: number;
    includeRetweets: boolean;
    twitterUsers: TwitterUserDto[];
}

interface TwitterTweetsSourceCardProps {
    source: TwitterTweetsSourceDto; 
}

const TwitterTweetsSourceCard: React.FC<TwitterTweetsSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.twitterCard}>
            <h2>Twitter Feed</h2>
            <p>Number of Tweets Per User: {source.numberOfTweetsPerUser}</p>
            <p>Include Retweets: {source.includeRetweets ? 'Yes' : 'No'}</p>
            {source.twitterUsers.map((user, index) => (
                <div key={index} className={styles.userCard}>
                    <img src={user.image} alt={user.fullName} className={styles.userImage} />
                    <div className={styles.userInfo}>
                        <h3>{user.fullName} {user.verified && <span className={styles.verified}>✔️</span>}</h3>
                        <p>@{user.username}</p>
                        <p>Followers: {user.followers}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TwitterTweetsSourceCard;
