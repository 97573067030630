import {AllTimeZonesProps} from '../components/UI/Select/Select.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { BuildLog } from 'api/types';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advanced);

declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];
}
const aryIannaTimeZones = Intl.supportedValuesOf('timeZone');

export const allTimeZones = <AllTimeZonesProps[]>(aryIannaTimeZones.map((timeZone) => ({
    offset: dayjs().tz(timeZone).format('Z'), timeZone: timeZone,
})).sort((a, b) => Number(b.offset.split(':')[0]) - Number(a.offset.split(':')[0])));


export const navLinks = [
    {
        id: 1,
        navLink: 'Users',
        route: 'users',
    },
    {
        id: 2,
        navLink: 'Sources',
        route: 'sources',
    },
    {
        id: 3,
        navLink: 'Analytics',
        route: 'analytics?range=daily',
    },
    {
        id: 4,
        navLink: 'Bull Board',
        route: 'bullboard',
    },
    {
        id: 5,
        navLink: 'Builds',
        route: 'builds',
    },
    {
        id: 6,
        navLink: 'Digests',
        route: 'digests',
    },



];

export const subNavLinks = [
    {
        id: 1,
        navLink: 'Details',
        route: 'details',
    },
    // {
    //     id: 2,
    //     navLink: 'Accounts',
    //     route: 'accounts',
    // },
];


export const userData = [
    {
        id: 14,
        status: 'All users',

    },
    {
        id: 1,
        status: 'Active',

    },
    {
        id: 2,
        status: 'Archived',

    },
    {
        id: 3,
        status: 'Deleted',

    },

];

export const analyticsMenuItems = [
    {
        id: 1,
        name: 'daily',
    },
    {
        id: 2,
        name: 'weekly',
    },
    {
        id: 3,
        name: 'monthly',
    },
    {
        id: 4,
        name: 'yearly',
    },
];

export const tableHeader = [
    {
        id: 1,
        title: 'Users by name and email',
    },
    {
        id: 2,
        title: 'Digest numbers',
    },
    {
        id: 3,
        title: 'Used sources',
    },

    {
        id: 4,
        title: 'Created profile',
    },
    {
        id:5,
        title:"Signup source"
    },
    {
        id:6,
        title:"Subscription Plan"
    },
    {
        id:6,
        title:"Newsletters Received"
    },
];

export const digestHeader = [
    {
        id: 1,
        title: 'Digest ID',
    },
    {
        id: 2,
        title: 'Name',
    },
    {
        id: 3,
        title: 'isActive',
    },
    // {
    //     id: 4,
    //     title: 'Total Builds',
    // },
    {
        id: 5,
        title: 'Type',
    },
    {
        id: 6,
        title: 'User Email',
    },
    {
        id: 7,
        title: 'CreatedAt',
    }
];

export const buildsHeader = [
    {
        id: 1,
        title: 'Build ID',
    },
    {
        id: 2,
        title: 'Build Number',
    },
    {
        id: 3,
        title: 'User email',
    },

    {
        id: 4,
        title: 'Build Emails Expected',
    },
    {
        id: 5,
        title: 'Build Emails Sent',
    },
    {
        id: 6,
        title: 'CreatedAt',
    }
];

export const sourcesTableHeader = [
    {
        id: 1,
        title: 'Sources by name',
    },
    {
        id: 2,
        title: 'Times used',
    },
    {
        id: 3,
        title: 'Average Build Duration',
    },
    {
        id: 4,
        title: 'Total Views Built',
    },
    {
        id: 5,
        title: 'Used by users',
    },
];

export function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
}

export function getLatestBuildLog(buildLogs: BuildLog[]) {
    if (!buildLogs || buildLogs.length === 0) {
        return null;
    }

    let latestLog = buildLogs[0];
    for (let i = 1; i < buildLogs.length; i++) {
        const currentLog = buildLogs[i];
        if (new Date(currentLog.createdAt) > new Date(latestLog.createdAt)) {
            latestLog = currentLog;
        }
    }

    return latestLog;
}

interface ScatterData {
    datasets: {
      label: string;
      data: { x: number; y: number }[];
      backgroundColor: string;
    }[];
  }
  
  interface DataItem {
    time: string;
    count: number;
  }
  
  export const convertToScatterData = (data: DataItem[]): ScatterData => {
    const datasets = [
      {
        label: 'Data',
        data: data.map(item => ({
          x: convertTimeToMinutes(item.time),
          y: item.count,
        })),
        backgroundColor: 'rgba(255, 99, 132, 1)', 
      },
    ];
  
    return { datasets };
  };
  
  const convertTimeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  
