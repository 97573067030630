import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

export const useDropDown = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const {pathname} = useLocation();

    useEffect(() => {
        setIsOpen(false)
    }, [pathname]);

    const toggleDropDown = () => {
        setIsOpen(!isOpen);
    };


    return {
        toggleDropDown,
        isOpen,
        setIsOpen
    }
}
