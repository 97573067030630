import axios, {errorCallBack} from './axios';
import {IForgotPasswordFormValues} from 'pages/ForgotPassword/interface';


interface IRecover {
    password: string,
    recoveryToken: string
}


type ForgotPasswordProps = {
    status: number,
    message: string,
    error: string
}


export const forgetPasswordQuery = ({email}: IForgotPasswordFormValues): Promise<{ data: ForgotPasswordProps }> => {
    return axios.post(`/admin/auth/password/forgot`, {email}, {
            headers: {
                'x-requested-with': `https://admin.digest-frontend.fireart-dev.com`,
            },
        },
    ).catch(errorCallBack);
};


export const recoverQuery = async ({password, recoveryToken}: IRecover): Promise<unknown> => {
    try {
        return await axios.post(`/admin/auth/password/recover`, {
            password,
            recoveryToken,
        }).catch(errorCallBack);
    } catch (e) {
        console.log(e);
    }
};