import React from 'react';
import styles from './WeatherSourceCard.module.scss'; 

export type WeatherSourceDto = {
    showHeading: boolean;
    locationName: string;
    forecastIntervalType: 1 | 5;
    temperatureType: 'C' | 'F';
    longitude: number;
    latitude: number;
    name: string
}

interface WeatherSourceCardProps {
    source: WeatherSourceDto; 
}

const WeatherSourceCard: React.FC<WeatherSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.weatherCard}>
            <h2>{source.name || 'Weather Information'}</h2>
            {source.showHeading && <h3>{source.locationName}</h3>}
            <p>Forecast Interval Type: {source.forecastIntervalType === 1 ? '1 hour' : '5 hours'}</p>
            <p>Temperature Type: {source.temperatureType === 'C' ? 'Celsius' : 'Fahrenheit'}</p>
            <p>Latitude: {source.latitude}</p>
            <p>Longitude: {source.longitude}</p>
        </div>
    );
};
export default WeatherSourceCard;
