import React, { useEffect, useState } from 'react';
import { useActions } from 'hooks/useActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BuildSourcesDTO, UserEditProfileProps, UserProps } from 'api/types';
import { useTypedSelector } from 'hooks/useTypedSelector';
import styles from './Digest.module.scss';
import BuildSourceModal from 'components/UI/BuildSourceModal';
import { formatDate } from 'helpers';

export enum ScheduleTypes {
    'daily' = 'daily',
    'weekly' = 'weekly',
    'monthly' = 'monthly',
}


const Digest = () => {
    const { digestId } = useParams();
    const { getDigestData } = useActions();
    const navigate = useNavigate();
    const {
        digestData
    } = useTypedSelector((state) => state.digestInformation);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [selectedSource, setSelectedSource] = useState({}); 
    const [isModalOpen, setIsModalOpen] = useState(false); 

    const modalHandler = () => {
        setIsModalOpened(!isModalOpened);
    };


    function openInNewTab(url: string | URL | undefined) {
        const newWindow = window.open(url, '_blank');
        if(newWindow) newWindow.focus();
    }

    useEffect(() => {
        digestId && getDigestData({digestId});
    }, []);

     const handleSourceClick = (source:BuildSourcesDTO) => {
        setSelectedSource(source);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    if (!digestData) {
        return null;
    }


    return (
        <>
        { digestData && digestData.name.length > 1 && <div className={styles.container}>
            <div className={styles.buildWrapper}>
                <div className={styles.buildInfo}>
                    <div className={styles.digestData}>
                        <p className={styles.name}>{digestData.name}</p>
                            <Link to={`/users/${digestData.user?.userId}`} className={styles.email}>
                                <a className={styles.email} >{digestData.user?.userEmail}</a>
                            </Link>
                       
                       <div className={styles.tags}>
                            {
                                digestData.active ? ( <p className={styles.isActive}>Active</p> ) 
                                : 
                                (<p className={styles.isInactive}>Inactive</p>)
                            }
                            {digestData.scheduleType && (
                                <p className={digestData.scheduleType === ScheduleTypes.daily ? styles.daily : styles.other}>
                                    {digestData.scheduleType.charAt(0).toUpperCase() + digestData.scheduleType.slice(1)}
                                </p>)
                            }

                       </div>
                    </div>
                </div>
            </div>
            <div className={styles.sources}>
                {digestData.sources && digestData.sources.map((source, index) => (
                    <div
                    className={styles.sourceWidget}
                    key={index}
                    onClick={() => handleSourceClick(source)}
                    >
                        <img src={source.icon} alt={source.type} className={styles.sourceIcon} />
                        <div className={styles.sourceInfo}>
                            <p className={styles.sourceName}>{source.name || source.sourceName || source.type.charAt(0).toUpperCase() + source.type.slice(1).toLowerCase()}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.emailSection}>
                <table className={styles.buildTable}>
                    <thead>
                        <tr>
                            <th>Builds</th>
                            <th>Builds Number</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {digestData.builds && digestData.builds.map((build, index) => (
                            <tr key={index}>
                                <td>
                                {build.buildId && <Link to={`/builds/${build.buildId}`} className={styles.buildLink}>
                                    {build.buildId}
                                </Link>}
                                </td>
                                <td>
                                    {build.buildNumber}
                                </td>
                                <td>
                                    { build.buildCreatedAt && formatDate(build.buildCreatedAt)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {
               digestData.subscribers &&  digestData.subscribers.length && (<div className={styles.emailSection}>
                    <table className={styles.buildTable}>
                        <thead>
                            <tr>
                                <th>Subscriber ID</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {digestData.subscribers.map((subscriber, index) => (
                                <tr key={index}>
                                    <td>
                                        {subscriber.subscriberId}
                                    </td>
                                    <td>
                                       {subscriber.subscriberEmail}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>) || <div></div>
            }
        </div>}
        {isModalOpen && (
                <BuildSourceModal
                    source={selectedSource}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default Digest;
