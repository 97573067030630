import React, { useEffect, useState } from 'react';
import { useActions } from 'hooks/useActions';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import {  formatDate, getLatestBuildLog } from 'helpers';
import { BuildSourcesDTO, UserEditProfileProps, UserProps } from 'api/types';
import { useTypedSelector } from 'hooks/useTypedSelector';
import styles from './Details.module.scss';
import BuildSourceModal from 'components/UI/BuildSourceModal';
import BuildContent from 'pages/BuildContent';
import { resendBuildQuery } from 'api/builds';
import { message } from 'antd';

export enum ScheduleTypes {
    'daily' = 'daily',
    'weekly' = 'weekly',
    'monthly' = 'monthly',
}


const Details = () => {
    const { buildId } = useParams();
    const { getBuildData  } = useActions();
    const {
        buildData
    } = useTypedSelector((state) => state.buildInformation);

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [selectedSource, setSelectedSource] = useState({}); 
    const [isModalOpen, setIsModalOpen] = useState(false); 

    const modalHandler = () => {
        setIsModalOpened(!isModalOpened);
    };

    const [showEmailViewer, setShowEmailViewer] = useState(false);

    const openInNewTab = (htmlContent:string) => {
        setShowEmailViewer(true);
    };

    // function openInNewTab(template: string) {
    //     const newUrl = `${currentPath}/view?template=${JSON.stringify(template)}`
    //     const newWindow = window.open(newUrl, '_blank', 'noopener,noreferrer');
    //     if(newWindow) newWindow.focus();
    // }

    useEffect(() => {
        buildId && getBuildData({buildId});
    }, []);

     const handleSourceClick = (source:BuildSourcesDTO) => {
        setSelectedSource(source);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleResendBuild = async () => {
        if(buildId){
            resendBuildQuery(buildId).then((data) => {
                message.success(`Successfully resent email with subject ${data.subject} to ${data.emails.length} emails`)
             });
        }
        
    };


    if (!buildData) {
        return null;
    }

    const buildLog = getLatestBuildLog(buildData.buildLogs);

    return (
        <>
        {buildData && buildData.buildId.length > 1 && !showEmailViewer && <div className={styles.container}>
            <div className={styles.buildWrapper}>
                <div className={styles.buildInfo}>
                    <div className={styles.numberContainer}>
                        <div className={styles.circle}>
                            {buildData.buildNumber}
                        </div>
                    </div>
                        <div className={styles.digestData}>
                            <p className={styles.name}>{buildData?.digest?.name}</p>
                            <p className={styles.date}>{buildData.createdAt && `${formatDate(buildData.createdAt)}`}</p>
                            <Link to={`/users/${buildData.digest?.user?.userId}`} className={styles.email}>
                                <a className={styles.email} href=''>{buildData.digest?.user?.email}</a>
                            </Link>
                           
                       <div className={styles.tags}>
                            {buildLog && buildLog.status === 'failed' ? (
                                    <p className={`${styles.status} ${styles.failed}`}>Failed</p>
                                ) : (
                                    <p className={`${styles.status} ${styles.success}`}>Successful</p>
                                )}
                            {
                                buildData.digest?.isPublic && <div>
                                    <div className={styles.isPublic}>
                                        Public
                                    </div>
                                </div>
                            }
                            {
                                buildData.digest?.active ? ( <p className={styles.isActive}>Active</p> ) 
                                : 
                                (<p className={styles.isInactive}>Inactive</p>)
                            }
                            {buildData.digest && buildData.digest.scheduleType && (
                                <p className={buildData.digest?.scheduleType === ScheduleTypes.daily ? styles.daily : styles.other}>
                                    {buildData.digest?.scheduleType.charAt(0).toUpperCase() + buildData.digest?.scheduleType?.slice(1)}
                                </p>)
                            }

                       </div>
                    </div>
                </div>
                
                <div className={styles.buildActions}>
                    <div className={styles.viewTemplatesBtn}>
                        <Button onClick={() => openInNewTab(buildData.buildEmail)} children="View Email Build" />
                    </div>
                    <div className={styles.secondDivider}></div>
                    <div className={styles.viewTemplatesBtn}>
                        <Button onClick={() => openInNewTab(buildData.buildWeb)} children="View Web Build"/>
                    </div>
                    <div className={styles.secondDivider}></div>
                    <div className={styles.viewTemplatesBtn}>
                        <Button onClick={handleResendBuild} children="Resend Build"/>
                    </div>
                    {
                        buildData.buildShare && typeof buildData.buildShare === 'string' && <div><div className={styles.secondDivider}></div>
                            <div className={styles.refreshBtn}>
                            <Button onClick={() => openInNewTab(buildData.buildShare as string)} children="Share Build"/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.sources}>
                {buildData.digest.sources.map((source, index) => (
                    <div
                    className={styles.sourceWidget}
                    key={index}
                    onClick={() => handleSourceClick(source)}
                    >
                        <img src={source.icon} alt={source.type} className={styles.sourceIcon} />
                        <div className={styles.sourceInfo}>
                            <p className={styles.sourceName}>{source.name || source.sourceName || source.type.charAt(0).toUpperCase() + source.type?.slice(1).toLowerCase()}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.emailSection}>
                <table className={styles.emailTable}>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {buildData.emailsExpected.map((email, index) => (
                            <tr key={index}>
                                <td>{email}</td>
                                <td>{buildData.emailsSent.includes(email) ? '✅' : '❌'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>}
        {isModalOpen && (
                <BuildSourceModal
                    source={selectedSource}
                    onClose={handleCloseModal}
                />
            )}
            {
                showEmailViewer && (<BuildContent content={buildData.buildEmail} setShowEmailViewer={setShowEmailViewer}/>)
            }
        </>
    );
};

export default Details;
