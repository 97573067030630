import React from 'react';
import { IButtonProps } from './Button.interface';
import styles from './Button.module.scss';


const Button: React.FC<IButtonProps> = ({ color, type, border, children, onClick, backgroundColor, Icon }) => {
    return (
        <button
            className={styles.primary}
            onClick={onClick}
            type={type}
            style={{
                backgroundColor: backgroundColor,
                color: color,
                border: border,
            }}
        >
            {Icon && <Icon />} {children}
        </button>
    );
};

export default Button;