import axios from 'axios';
import {saveState, getState, removeState} from './LocalStorage';

const API_URL = process.env.REACT_APP_API;

export const apiQuery = async <T>(myQuery: () => T): Promise<T> => {
    try {
        return await myQuery();
    } catch (error: any) {
        if (error?.response?.data?.status === 401) {
            try {
                const {refreshToken} = getState('admin_token');

                const {data} = await axios.post(`${API_URL}/auth/refresh`, {
                    refreshToken,
                });

                await saveState('admin_token', {
                    accessToken: data.token.accessToken,
                    refreshToken: data.token.refreshToken,
                });
                return await myQuery();
            } catch (e) {
                removeState('admin_token');
                throw new Error('');
            }
        }
        throw error;
    }
};