import React, { useEffect, useState } from 'react';
import { Spin } from "antd";
import styles from './BuildContent.module.scss';
import Button from 'components/Button';

const BuildContent = (data: { content: any; setShowEmailViewer: (arg0: boolean) => void; } ) => {
    const content = data.content;

    const closeBuildContent = ()=>{
        data.setShowEmailViewer(false)
    }

    return (
        <div className={styles.container}>
            <div className={styles.buildWrapper}>
                {
                    !content && <Spin className="block" />
                }
                {
                    content &&  <div className={styles.refreshBtn}>
                    <Button onClick={() => closeBuildContent()} children="Close build Preview"/>
                    </div>
                }
                {
                   content && <div
                    className={styles.htmlContent}
                    dangerouslySetInnerHTML={{ __html: content }}
                    />
                }
            </div>
        </div>
    );
};

export default BuildContent;
