import React from 'react';
import styles from './HackerNewsSourceCard.module.scss';

export type HackerNewsSourceDto = {
    name?: string;
    showHeading: boolean;
    numberOfPosts: number;
    layout: 1 | 2;
    story: boolean;
    askHN: boolean;
    showHN: boolean;
}

interface HackerNewsSourceCardProps {
    source: HackerNewsSourceDto; 
}

const HackerNewsSourceCard: React.FC<HackerNewsSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.hackerNewsCard}>
            <h2>{source.name || 'Hacker News'}</h2>
            {source.showHeading && <h3>Hacker News Posts</h3>}
            <p>Number of Posts: {source.numberOfPosts}</p>
            <p>Layout: {source.layout === 1 ? 'Layout 1' : 'Layout 2'}</p>
            <p>Include Stories: {source.story ? 'Yes' : 'No'}</p>
            <p>Include Ask HN: {source.askHN ? 'Yes' : 'No'}</p>
            <p>Include Show HN: {source.showHN ? 'Yes' : 'No'}</p>
        </div>
    );
};

export default HackerNewsSourceCard;
