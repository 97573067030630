import { BuildProps } from 'store/reducers/Builds';
import axios, {errorCallBack} from './axios';
import { BuildsDetailsProps, ResendBuildsProps } from './types';
import { HealthReport } from 'pages/Sources/health-report-types';


interface IParams {
    searchParams: string | null,
    limit: string | null,
    sort: string | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    page: string | null,
}

export const getBuildsQuery = ({
                                  searchParams,
                                  sort,
                                  dateFrom,
                                  dateTo,
                                  limit,
                                  page,
                              }: IParams): Promise<{ builds: BuildProps[], dist: number }> => {
    return axios.get(`/admin/builds?${searchParams ? `search=${searchParams}&` : ""}${sort ? `sort=${sort}&` : ""}${dateFrom ? `from=${dateFrom}&` : ""}${dateTo ? `to=${dateTo}&` : ""}${limit ? `limit=${limit}&` : ""}${page ? `page=${page}&` : ""}`).then(({ data }) => {
        return data
    }).catch(errorCallBack);
};

export const getSourceHealthQuery = (): Promise<HealthReport[]> => {
    return axios.get(`/admin/sources/health`).then(({ data }) => {
        return data
    }).catch(errorCallBack);
};

export const getBuildQuery = (buildId: string): Promise<{build: BuildsDetailsProps}> => {
    return axios.get(`/admin/builds/${buildId}`).then(({data}) => data).catch(errorCallBack);
};

export const resendBuildQuery = (buildId: string): Promise<ResendBuildsProps> => {
    return axios.post(`/admin/builds/${buildId}/send`).then(({data}) => data).catch(errorCallBack);
};