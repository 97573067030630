import React from 'react';
import styles from './GoogleCalendarSourceCard.module.scss'; 

export enum GoogleCalendarEventTypeFilter {
    tomorrow = 'tomorrow',
    today = 'today',
    week = 'week',
}

export enum GoogleCalendarTimeFilter {
    '12h' = '12h',
    '24h' = '24h',
}

export type GoogleCalendarEventQueryItemDto =  {
    tokenId: string;
    calendarIds: string[];
}

export type GoogleCalendarSourceDto = {
    showHeading: boolean;
    name?: string;
    eventType: GoogleCalendarEventTypeFilter;
    eventTime: GoogleCalendarTimeFilter; 
    selectedEvents: GoogleCalendarEventQueryItemDto[]; 
}

interface GoogleCalendarSourceCardProps {
    source: GoogleCalendarSourceDto; 
}

const GoogleCalendarSourceCard: React.FC<GoogleCalendarSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.googleCalendarCard}>
            <h2>{source.name || 'Google Calendar'}</h2>
            {source.showHeading && <h3>Events</h3>}
            <p>Event Type: {source.eventType}</p>
            <p>Event Time: {source.eventTime}</p>
            <ul>
            </ul>
        </div>
    );
};

export default GoogleCalendarSourceCard;
