import React, { FC } from 'react';
import Button from '../Button';

import styles from './AccountCard.module.scss';

interface IAccountCard {
    connected?: boolean,
}

const AccountCard: FC<IAccountCard> = ({ connected }) => {

    return (
        <div className={styles.container}>
            <div className={styles.socialAccount}>
                <div className={styles.picture}></div>
                <p className={styles.socialMediaName}>Twitter</p>
            </div>
            <div className={styles.connectionWrapper}>
                <div className={styles.text}>
                    {connected ?
                        <>
                            <p className={styles.mainText}>Connected to</p>
                            <p className={styles.subtext}>Fireart Digest</p>
                        </> : <p className={styles.mainText}>No Connection to</p>
                    }
                </div>
                <>
                    {connected ?
                        <div className={styles.btnContainer}>
                            <Button children='Disconnect' />
                        </div>
                        :
                        <div className={styles.instructionBtn}>
                            <Button children='Send Instructions' />
                        </div>
                    }
                </>
            </div>
        </div>
    );
};

export default AccountCard;