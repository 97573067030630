import React from 'react';
import styles from './InstagramSourceCard.module.scss';

export type InstagramUserDto = {
    instagramUserId: string;
    fullName: string;
    username: string;
    image?: string;
    followers?: string;
    verified: boolean;
};

export type InstagramSourceDto = {
    showHeading: boolean;
    layout: 1 | 2;
    limit: number;
    numberOfPostsPerUser: number;
    instagramUsers: InstagramUserDto[];
    name?: string;
};

interface InstagramSourceCardProps {
    source: InstagramSourceDto;
}

const InstagramSourceCard: React.FC<InstagramSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.instagramCard}>
            <h2>{source.name || 'Instagram Feed'}</h2>
            {source.showHeading && <h3>Layout: {source.layout === 1 ? 'Grid' : 'List'}</h3>}
            <p>Limit: {source.limit} posts</p>
            <p>Posts per User: {source.numberOfPostsPerUser}</p>
            <div className={styles.usersContainer}>
                {source.instagramUsers.map(user => (
                    <div key={user.instagramUserId} className={styles.userCard}>
                        <img src={user.image || 'default-avatar.png'} alt={user.username} className={styles.userImage} />
                        <div className={styles.userInfo}>
                            <h4>{user.fullName} {user.verified && <span className={styles.verifiedBadge}>✔</span>}</h4>
                            <p>@{user.username}</p>
                            <p>{user.followers ? `${user.followers} followers` : 'No follower count available'}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InstagramSourceCard;
