import React from 'react';
import styles from './YoutubeSourceCard.module.scss';

export type YoutubeSourceDto = {
    channelName: string;
    showHeading: boolean;
    sourceName: string;
    numberOfPosts: number;
    images: 'show' | 'hide';
    layout: 1 | 2;
    channelId: string;
}

interface YoutubeSourceCardProps {
    source: YoutubeSourceDto; 
}

const YoutubeSourceCard: React.FC<YoutubeSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.youtubeCard}>
            <h2>{source.sourceName || 'YouTube'}</h2>
            {source.showHeading && <h3>{source.channelName}</h3>}
            <p>Number of Posts: {source.numberOfPosts}</p>
            <p>Layout: {source.layout === 1 ? 'Layout 1' : 'Layout 2'}</p>
            <p>Images: {source.images === 'show' ? 'Show' : 'Hide'}</p>
            <p>Channel ID: {source.channelId}</p>
        </div>
    );
};

export default YoutubeSourceCard;
