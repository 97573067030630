import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Layout from 'components/Layout';
import {useTypedSelector} from 'hooks/useTypedSelector';
import {getState} from 'helpers/LocalStorage';
import User from 'pages/User';
import Users from 'pages/Users';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import RecoverMessage from 'pages/RecoverMessage';
import Analytics from 'pages/Analytics';
import Sources from 'pages/Sources';
import Details from 'pages/Details';
import Accounts from 'pages/Accounts';
import LogIn from 'pages/LogIn';
import Loading from 'components/UI/Loading';
import BullDashboard from 'pages/ServerMetrics';
import Builds from 'pages/Builds';
import Build from 'pages/BuildDetail'
import Digests from 'pages/Digests';
import Digest from 'pages/DigestDetail';
import BuildContent from 'pages/BuildContent';
import { message } from 'antd';



const MainRoutes = () => {
    const {isAuth, forgetPasswordEmail, isLoading} = useTypedSelector((state) => state.userCredentials);
    const token = getState('admin_token');
    const [, contextHolder] = message.useMessage();

    return (
        <>
            {contextHolder}
            {isLoading ? (
                <Loading/>
            ) : (
                <Routes>
                    <Route element={<Layout/>}>
                        {token?.accessToken || isAuth ? (
                            <>

                                <Route path='/*' element={<Navigate replace to='/users'/>}/>
                                <Route path='/users' element={<Users/>}/>
                                <Route path='/users/:userId' element={<User/>}>
                                    <Route path='/users/:userId' element={<Navigate replace to='details'/>}/>
                                    <Route path='details' element={<Details/>}/>
                                    <Route path='accounts' element={<Accounts/>}/>
                                </Route>
                                <Route path='/sources' element={<Sources/>}/>
                                <Route path='/analytics' element={<Analytics/>}/>
                                <Route path='/login' element={<Navigate to='/' replace/>}/>
                                <Route path='/bullboard' element={<BullDashboard />} />

                                <Route path='/digests' element={<Digests />} />
                                <Route path='/digests/:digestId' element={<Digest />} />
                                <Route path='/builds' element={<Builds />} />
                                <Route path='/builds/:buildId' element={<Build />} />
                                
                                
                            </>
                        ) : (
                            <>
                                <Route path='/reset-password' element={<ResetPassword/>}/>
                                <Route
                                    path='/recover-message'
                                    element={
                                        forgetPasswordEmail ? <RecoverMessage/> : <Navigate to='/login' replace/>
                                    }
                                />
                                <Route path='/logIn' element={<LogIn/>}/>
                                <Route path='/*' element={<Navigate to='/login' replace/>}/>
                                <Route path='/forgetPassword' element={<ForgotPassword/>}/>
                            </>
                        )}
                    </Route>
                </Routes>
            )}
        </>
    );
};

export default MainRoutes;
