import React from 'react';
import CsvDownloadButton from 'react-json-to-csv'


const CsvButton = ({data, children}: any) => {

    return (
        <CsvDownloadButton data={data}>{children}</CsvDownloadButton>
    );
}

export default CsvButton;