import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.scss';

interface IModalProps {
    children?: ReactNode;
    onConfirm?: () => void;
}

const Backdrop = (props: any) => {
    return <div className={styles.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay: FC<IModalProps> = ({ children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    );
};

const Modal: FC<IModalProps> = ({ children, onConfirm }) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onConfirm={onConfirm} />,
                document.getElementById('backdrop-root')!,
            )}
            {ReactDOM.createPortal(
                <ModalOverlay>
                    {children}
                </ModalOverlay>,
                document.getElementById('overlay-root')!,
            )}
        </React.Fragment>
    );
};

export default Modal;