import React, {useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useActions} from 'hooks/useActions';
import {useTypedSelector} from 'hooks/useTypedSelector';

import {IGetDigestsDataParams} from "../../store/action-creators/Digests";


const searchDigests = (query: () => void) => {
    let interval = setTimeout(() => {
        query();
    }, 0);
    return (query: () => void) => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            query();
        }, 1000);
    };
};

const tableContainer = ({getData, locationParams}: {
    locationParams: string,
    getData: ({
                  searchParams,
                  sort,
                  page,
                  dateFrom,
                  dateTo,
                  limit
              }: IGetDigestsDataParams) => void
}) => {
    const {logout} = useActions();
    const navigate = useNavigate()
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const searchParams = params.get('search');
    const sort = params.get('sort');
    const dateFrom = params.get('dateFrom');
    const dateTo = params.get('dateTo');
    const limit = params.get('limit');
    const page = params.get('page');
    const [defaultPageSize, setDefaultPageSize] = useState(limit ? Number(limit) : 10)
    const [startDate, setStartDate] = useState<Date | null>(!dateFrom ? null : new Date(dateFrom));
    const [endDate, setEndDate] = useState<Date | null>(!dateTo ? null : new Date(dateTo));

    const getDigests = useMemo(
        () => {
            setStartDate(!dateFrom ? null : new Date(dateFrom));
            setEndDate(!dateTo ? null : new Date(dateTo));
            return searchDigests(() => getData({
                searchParams,
                sort,
                limit,
                page,
                dateFrom: !dateFrom ? null : new Date(dateFrom),
                dateTo: !dateTo ? null : new Date(dateTo)
            }))
        },
        [sort, dateFrom, dateTo, limit, page]
    );
    const onChangeHandler = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        navigate(`/${locationParams}?${searchParams ? `search=${searchParams}&` : ''}${sort ? `sort=${sort}&` : ""}${limit ? `limit=${limit}&` : ""}${page ? `page=${page}&` : ""}${start ? `dateFrom=${start?.getDate()}&` : ""}${end ? `dateTo=${end?.getDate()}&` : ""}`);
        start && setStartDate(start);
        end && setEndDate(end);
    };

    const searchDigestsHandler = (linkParams: string, e?: React.ChangeEvent<HTMLInputElement>,) => {
        navigate(`/${locationParams}?${e?.target.value ? `search=${e?.target.value}&` : ''}${linkParams}`);
        getDigests(() => {
            getData({
                searchParams: e?.target.value || null,
                sort,
                dateFrom: startDate,
                limit,
                page,
                dateTo: endDate
            });
        });
    };
    const onShowSizeChange = (current: number, pageSize: number) => {
        setDefaultPageSize(pageSize)
    };
    const selectHandler = (pageSize: string) => {
        setDefaultPageSize(Number(pageSize))

        navigate(`/${locationParams}?${searchParams ? `search=${searchParams}&` : ''}${sort ? `sort=${sort}&` : ""}${pageSize ? `limit=${pageSize}&` : ""}${dateFrom ? `dateFrom=${dateFrom}&` : ""}${dateTo ? `dateTo=${dateTo}&` : ""}`);
    };
    const onChangePagination = (current: number, pageSize: number) => {
        navigate(`/${locationParams}?${searchParams ? `search=${searchParams}&` : ''}${sort ? `sort=${sort}&` : ""}${current ? `page=${current}&` : ""}${limit ? `limit=${pageSize}&` : ""}${dateFrom ? `dateFrom=${dateFrom}&` : ""}${dateTo ? `dateTo=${dateTo}&` : ""}`);

    };

    const dd = `${limit ? `limit=${limit}&` : ""}${dateFrom ? `dateFrom=${dateFrom}&` : ""}${dateTo ? `dateTo=${dateTo}&` : ""}`

    return {
        onChangeHandler,
        endDate,
        selectHandler,
        logout,
        defaultPageSize,
        onShowSizeChange,
        onChangePagination,
        searchParams,
        searchDigestsHandler,
        sort,
        dd,
        page,
        limit,
        startDate
    }

};

export default tableContainer;