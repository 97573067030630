import React, { FC } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

export interface ICustomLinkProps {
    to: string,
    children?: React.ReactNode,
    onClick?: () => void
}

const CustomLink: FC<ICustomLinkProps> = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <li className={isActive ? 'active' : ''}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    );
};

export default CustomLink;