import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from 'components/UI/Input/Input';
import Label from 'components/UI/Label';
import Button from 'components/Button';
import { IRecoverFormValues } from './interfaces';
import { recoverQuery } from 'api/authService';

import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
    const { search } = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(search);
    const recoveryToken = searchParams.get('recoveryToken');

    const { register, handleSubmit, formState: { errors } } = useForm<IRecoverFormValues>();

    const onSubmit = ({ password }: IRecoverFormValues) => {
        if (recoveryToken) {
            recoverQuery({ recoveryToken, password }).then(() => {
                navigate('/login');
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.formContainer}>
                <h1 className={styles.formTitle}>Reset your Password</h1>
                <p className={styles.formText}>Enter a new password for further login</p>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formRow}>
                        <Label htmlFor='pass' mainLabel='New password' />
                        <div className={styles.inputWrapper}>
                            <Input
                                type='password'
                                isError={!!errors.password}
                                aria-invalid={errors.password ? 'true' : 'false'}
                                {...register('password', {
                                    required: true,
                                    pattern: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
                                })}
                                placeholder='Create a new password' />
                            {errors.password && errors.password.type === 'pattern' && (
                                <span role='alert' className={styles.errorMessage}>Password should have at least 8 characters contain a letter, one number and one special character </span>
                            )}
                            {errors.password && (
                                <span role='alert' className={styles.errorMessage}>This field is required </span>
                            )}
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.btnContainer}>
                            <Button
                                type='submit'
                                children='Reset Password' />
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default ResetPassword;