import React from 'react';
import styles from './SubMenu.module.scss';
import { ISubMenuInterface } from './ISubMenu.interface';

const SubMenu: React.FC<ISubMenuInterface> = ({ children }) => {
    return (
        <div className={styles.menuContainer}>
            {children}
        </div>
    );
};

export default SubMenu;