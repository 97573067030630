import {UserDataProps, UserEditProfileProps, UserProps, UsersProps} from 'api/types';
import { HealthReport } from 'pages/Sources/health-report-types';

export enum UserActionTypes {
    FETCHING_DATA = 'FETCHING_DATA',
    USER_SIGN_IN = 'USER_SIGN_IN',
    FETCHING_SOURCES = 'FETCHING_SOURCES',
    UPLOAD_RSS_URLS = 'UPLOAD_RSS_URLS',
    SET_USER_DATA = 'SET_USER_DATA',
    EDITING_USER_DATA = 'EDITING_USER_DATA',
    FETCH_ERROR = 'FETCH_ERROR',
    USER_LOGIN = 'USER_LOGIN',
    USER_INFORMATION = 'USER_INFORMATION',
    START_LOADING = 'START_LOADING',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    CLEAR_MESSAGE = 'CLEAR_MESSAGE',
    START_EDIT_LOADING = 'START_EDIT_LOADING',
    USER_LOGOUT = 'USER_LOGOUT',
    SET_USER_IMAGE = 'SET_USER_IMAGE',
    USER_FORGET_PASSWORD = 'USER_FORGET_PASSWORD',
    SET_ALL_TO_FREE_TRIAL = 'SET_ALL_TO_FREE_TRIAL',
    FETCHING_SOURCE_HEALTH = "FETCHING_SOURCE_HEALTH"
}

interface LoginUser {
    type: UserActionTypes.USER_LOGIN;
}

interface SignInUser {
    type: UserActionTypes.USER_SIGN_IN;
    payload: any;
}

interface UserForgetPassword {
    type: UserActionTypes.USER_FORGET_PASSWORD,
    payload: string
}

interface SetUserImage {
    type: UserActionTypes.SET_USER_IMAGE;
    payload: UserDataProps;
}

interface EditDataLoading {
    type: UserActionTypes.START_EDIT_LOADING;
}

interface FetchData {
    type: UserActionTypes.FETCHING_DATA;
    payload: { users: UsersProps[], dist: number };
}

interface FetchSourcesData {
    type: UserActionTypes.FETCHING_SOURCES;
    payload: any;
}

interface FetchSourcesHealthData {
    type: UserActionTypes.FETCHING_SOURCE_HEALTH;
    payload: HealthReport[];
}

interface FetchUserData {
    type: UserActionTypes.SET_USER_DATA;
    payload: UserDataProps;
}

interface UserInformation {
    type: UserActionTypes.USER_INFORMATION;
    payload: UserEditProfileProps;
}

interface EditUserData {
    type: UserActionTypes.EDITING_USER_DATA;
    payload: UserEditProfileProps;
}

interface ClearErrorMessage {
    type: UserActionTypes.CLEAR_MESSAGE;
    payload?: string;
}

interface Success {
    type: UserActionTypes.FETCH_SUCCESS;
    payload?: string;
}

interface Loading {
    type: UserActionTypes.START_LOADING;
}

interface Error {
    type: UserActionTypes.FETCH_ERROR;
    payload?: string;
}


interface UserLogout {
    type: UserActionTypes.USER_LOGOUT;
}

interface SetAllToFreeTrial {
    type: UserActionTypes.SET_ALL_TO_FREE_TRIAL;
    message: string
}

export type UserAction =
    | FetchData
    | FetchUserData
    | FetchSourcesHealthData
    | Error
    | EditUserData
    | Loading
    | Success
    | LoginUser
    | UserLogout
    | ClearErrorMessage
    | UserForgetPassword
    | SignInUser
    | FetchSourcesData
    | EditDataLoading
    | UserInformation
    | SetUserImage
    | SetAllToFreeTrial