import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ReactComponent as DownloadSvg } from 'assets/svg/upload.svg';
import { ReactComponent as SearchSvg } from 'assets/svg/search.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/svg/threedots.svg';
import { ReactComponent as UserSvg } from 'assets/svg/user.svg';
import { ReactComponent as LogoutSvg } from 'assets/svg/logout.svg';
import { ReactComponent as DownArrowSvg } from 'assets/svg/arrowDown.svg';
import Divider from 'components/UI/Divider';
import Table from 'components/UI/Table';
import Calendar from 'components/UI/Calendar';
import PaginationComponent from 'components/UI/Pagination';
import PageSizer from 'components/UI/PageSizer/PageSizer';
import { tableHeader } from 'helpers';
import NoAvatarSvg from 'assets/svg/noAvatar.svg';
import tableContainer from './TableContainer';
import CsvButton from 'components/CsvButton/CsvButton';
import styles from './Users.module.scss';
import { Button, message } from 'antd';
import { setAllToFreeTrialQuery } from 'api/users'

const Users = () => {
    const { getUsersData } = useActions();
    const { usersData, dist } = useTypedSelector((state) => state.users);
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/users?sort=datedesc');
    }, []);
    const {
        onChangeHandler,
        endDate,
        selectHandler,
        logout,
        defaultPageSize,
        onShowSizeChange,
        onChangePagination,
        searchParams,
        searchUserHandler,
        sort,
        dd,
        page,
        limit,
        startDate,
    } = tableContainer({ getData: getUsersData, locationParams: 'users' });

    const onClickEnableFreeTrial = () => {
        setAllToFreeTrialQuery().then((data) => {
               message.success(data.message)
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h1 className={styles.mainTitle}>User information</h1>
                <p className={styles.mobileTitleSubtext}>View your all users that registered.</p>
                <div className={styles.buttonsContainer}>
                    <div className={styles.downloadBtn}>
                        <CsvButton data={usersData}>
                            {' '}
                            <DownloadSvg /> Download CSV
                        </CsvButton>
                    </div>
                </div>

            </div>
            <p className={styles.titleSubtext}>View your all users that registered.</p>
            <div className={styles.navigationBox}>
                <nav className={styles.navContainer}>
                    <ul>
                        <li>All users</li>
                    </ul>
                </nav>
                <div className={styles.logoutContainer} onClick={logout}>
                    <LogoutSvg className={styles.logoutSvg} />
                    <span className={styles.text}>Log Out</span>
                </div>
            </div>

            <div className={styles.divider}>
                <Divider />
            </div>
            <div className={styles.dataCalendar}>
                <div className={styles.searchInputWrapper}>
                    <input
                        type="text"
                        defaultValue={searchParams || ''}
                        placeholder="Search for users"
                        onChange={(e) => {
                            searchUserHandler(`${sort ? `sort=${sort}&` : ''}${dd}`, e);
                        }}
                    />
                    <div className={styles.searchIcon}>
                        <SearchSvg />
                    </div>
                </div>
                <div className={styles.logoutContainer}>
                    {/* <Button
                        danger
                        onClick={onClickEnableFreeTrial}
                    >Enable Free Trial
                    </Button> */}
                </div>
                <div className={styles.calendarWrapper}>
                    <div className={styles.dataPickers}>
                        <Calendar isMaxDate startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    </div>
                </div>
            </div>
            <div className={styles.tableHeader}>
                <h2 className={styles.title}>All Users - total {dist}</h2>
                <ThreeDotsSvg />
            </div>
            <Table>
                <thead>
                    <tr>
                        {tableHeader.map((item) => {
                            return (
                                <th key={item.id}>
                                    <div className={styles.columnHeader}>
                                        {!(item.title === 'Created profile' || item.title === 'Digest numbers') &&
                                            item.title}
                                        {item.title === 'Created profile' && (
                                            <Link
                                                to={`/users?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'dateasc' ? 'datedesc' : 'dateasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort !== 'numasc' && sort !== 'numdesc' && sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'dateasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                        {item.title === 'Digest numbers' && (
                                            <Link
                                                to={`/users?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'numasc' ? 'numdesc' : 'numasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort !== 'dateasc' && sort !== 'datedesc' && sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'numasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {usersData.map((user) => {
                        let remainingUsersCount;
                        if (user.sources.length > 5) {
                            remainingUsersCount = user.sources.length - 5;
                        }
                        return (
                            <tr key={user.userId}>
                                <td>
                                    <div className={styles.userInfoColumn}>
                                        <div className={styles.image}>
                                            {user.profileImg ? (
                                                <img src={user.profileImg} alt="avatar" />
                                            ) : (
                                                <img src={NoAvatarSvg} alt="no-avatar" />
                                            )}
                                        </div>
                                        <Link to={user.userId} className={styles.userCredentials}>
                                            <span className={styles.name}>{user.fullName}</span>
                                            <span className={styles.email}>{user.email}</span>
                                        </Link>
                                    </div>
                                </td>
                                <td className={styles.digestNumberColumn}>{user.digestNumbers}</td>
                                <td>
                                    <div className={styles.sourcesColumn}>
                                        <span className={styles.sourceNumber}>{user.sources.length}</span>
                                        <div className={styles.imageContainer}>
                                            {user.sources &&
                                                user.sources.slice(0, 5).map((img: string, index: number) => {
                                                    return (
                                                        <div key={index} className={styles.image}>
                                                            <img src={img} alt="user-Avatar" />
                                                        </div>
                                                    );
                                                })}
                                            {user.sources && user.sources.length > 5 && (
                                                <div className={styles.countContainer}>
                                                    <span className={styles.count}>+{remainingUsersCount}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td className={styles.dateColumn}>{dayjs(user.createdAt).format('MMM DD YYYY')}</td>
                                <td className={styles.dateColumn}>{user.method}</td>
                                <td className={styles.dateColumn}>{user.subscriptionPlan}</td>
                                <td className={styles.dateColumn}>{user.userMailNumbers}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.tableFooter}>
                <div className={styles.pageSizerContainer}>
                    <UserSvg />
                    <span className={styles.text}> Accounts per page</span>
                    <div className={styles.sizerWrapper}>
                        <PageSizer currentItem={limit || '10'} onClick={selectHandler} array={['10', '20', '50']} />
                    </div>
                </div>
                <PaginationComponent
                    defaultPageSize={defaultPageSize}
                    total={dist}
                    defaultCurrent={page ? Number(page) : 1}
                    onChange={onChangePagination}
                    onShowSizeChange={onShowSizeChange}
                />
            </div>
        </div>
    );
};

export default Users;
