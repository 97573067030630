import React, {useRef, FC, useState, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as DownloadSvg } from 'assets/svg/upload.svg';
import NoAvatar from 'assets/svg/noAvatar.svg';
import { ReactComponent as UserSvg } from 'assets/svg/user.svg';
import { ReactComponent as SearchSvg } from 'assets/svg/search.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/svg/threedots.svg';
import {ReactComponent as UploadSvg} from 'assets/svg/upload.svg';
import { ReactComponent as DownArrowSvg } from 'assets/svg/arrowDown.svg';
import { sourcesTableHeader, userData } from 'helpers';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useActions } from 'hooks/useActions';
import Divider from 'components/UI/Divider';
import Calendar from 'components/UI/Calendar';
import Table from 'components/UI/Table';
import tableContainer from '../Users/TableContainer';
import PageSizer from 'components/UI/PageSizer/PageSizer';
import PaginationComponent from 'components/UI/Pagination';
import CsvButton from 'components/CsvButton/CsvButton';
import styles from './Sources.module.scss';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import HealthReportComponent from './SorcesHealthStatus';
import { DataSourceTypes } from 'components/StackedBarChart/StackedBarChart';
import { HealthStatusToNameMap } from './health-report-types';
import { fetchQuery } from './util';

const API_URL = process.env.REACT_APP_API;

const Sources = () => {
    const { getSourcesData, uploadRSSURL, addRSSFile, getSourceHeathStatus } = useActions();
    
    useMemo(
        () => {
            return fetchQuery(() => getSourceHeathStatus())
        },
        []
    );

    const { sourcesData, dist } = useTypedSelector((state) => state.sources);
    const { sourcesHealthReport } = useTypedSelector((state) => state.sourcesHealth);
    const [csv, setCSV] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);


    const filesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.type === "text/csv") {
            setCSV(file)
        } else {
            console.error('Please select a CSV file.');
        }
    };

    const submit = async () => {
        if(csv){
            const url = await addRSSFile(csv)
            if(fileInputRef && fileInputRef.current && fileInputRef.current.value){
                fileInputRef.current.value = ''; 
            }

            const rssUrl = url as any as string

            if(rssUrl){
                const upload = await uploadRSSURL(rssUrl)
                message.info(upload.toString())
            }
        }else{
            message.error("Please select a file")
        }
    }

    const {
        searchUserHandler,
        sort,
        dd,
        startDate,
        onChangeHandler,
        searchParams,
        endDate,
        limit,
        selectHandler,
        defaultPageSize,
        page,
        onChangePagination,
        onShowSizeChange,
    } = tableContainer({ getData: getSourcesData, locationParams: 'sources' });

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h1 className={styles.mainTitle}>Source information</h1>
                <p className={styles.mobileTitleSubtext}>View your all sources that used by users.</p>
                <div className={styles.buttonsContainer}>
                    <div className={styles.downloadBtn}>
                        <CsvButton data={sourcesData}>
                            {' '}
                            <DownloadSvg /> Download CSV
                        </CsvButton>
                    </div>
                </div>
            </div>
            <p className={styles.titleSubtext}>View your all sources that used by users.</p>
            <nav className={styles.navContainer}>
                <ul>
                    <li>All sources</li>
                </ul>
            </nav>
            <div className={styles.divider}>
                <Divider />
            </div>
            <div className={styles.dataCalendar}>
                <div className={styles.searchInputWrapper}>
                    <input
                        onChange={(e) => {
                            searchUserHandler(`${sort ? `sort=${sort}&` : ''}${dd}`, e);
                        }}
                        type="text"
                        placeholder="Search for sources"
                    />
                    <div className={styles.searchIcon}>
                        <SearchSvg />
                    </div>
                </div>
                <div className={styles.calendarWrapper}>
                    <div className={styles.dataPickers}>
                        <Calendar startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    </div>
                </div>
            </div>

            <div className={styles.tableHeader}>
                <h2 className={styles.title}>Add RSS URLS</h2>
                <div >
                    <input
                        name={"Upload CSV"}
                        className={styles.fileInput}
                        type='file'
                        ref={fileInputRef}
                        accept='.csv'
                        onChange={filesSelected}
                    />
                    <button type="button" className={styles.addBtn} onClick={submit}>
                        <UploadSvg></UploadSvg>
                    </button>
                </div>
                <ThreeDotsSvg />
            </div>

            <div className={styles.tableHeader}>
                <h2 className={styles.title}>External Sources API Health Dashboard </h2>
                <ThreeDotsSvg />
            </div>

            <HealthReportComponent healthReports={sourcesHealthReport}></HealthReportComponent>

            <div className={styles.tableHeader}>
                <h2 className={styles.title}>All Sources</h2>
                <ThreeDotsSvg />
            </div>
            <Table>
                <thead>
                    <tr>
                        {sourcesTableHeader.map((item) => {
                            return (
                                <th key={item.id}>
                                    <div className={styles.columnHeader}>
                                        {!(item.title === 'Times used') &&
                                            !(item.title === 'Used by users') &&
                                            !(item.title === 'Average Build Duration') &&
                                            item.title}
                                        {item.title === 'Times used' && (
                                            <Link
                                                to={`/sources?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'usesasc' ? 'usesdesc' : 'usesasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'usesasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                        {item.title === 'Average Build Duration' && (
                                            <Link
                                                to={`/sources?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'durationasc' ? 'durationdesc' : 'durationasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'durationasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                        {item.title === 'Used by users' && (
                                            <Link
                                                to={`/sources?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'usersasc' ? 'usersdesc' : 'usersasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'usersasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sourcesData && sourcesData?.map((source, index) => {
                        let remainingUsersCount;
                        if (source.userIcons?.length > 5) {
                            remainingUsersCount = source.userIcons.length - 5;
                        }
                        return (
                            <tr key={index}>
                                <td>
                                    <div className={styles.userInfoColumn}>
                                        <div className={styles.image}>
                                            <img src={source.icon} alt="user-avatar" />
                                        </div>
                                        <Link to={source.id} className={styles.userCredentials}>
                                            <span className={styles.name}>{source.name}</span>
                                        </Link>
                                    </div>
                                </td>
                                <td className={styles.digestNumberColumn}> {source.timesUsed}</td>
                                <td className={styles.digestNumberColumn}> {(source.avgDuration / 1000).toFixed(2)} s</td>
                                <td className={styles.digestNumberColumn}> {source.totalLength}</td>
                                <td>
                                    <div className={styles.sourcesColumn}>
                                        <span className={styles.sourceNumber}>{source.usedByUsers}</span>
                                        <div className={styles.imageContainer}>
                                            {source.userIcons &&
                                                source.userIcons.slice(0, 5).map((icon: string, index: number) => {
                                                    if (icon) {
                                                        return (
                                                            <div key={index} className={styles.image}>
                                                                <img src={icon} alt="source-icon" />
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div key={index} className={styles.image}>
                                                                <img src={NoAvatar} alt="no-icon" />
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            {source.userIcons && source.userIcons.length > 5 && (
                                                <div className={styles.image}>
                                                    <span className={styles.count}> + {remainingUsersCount}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.tableFooter}>
                <div className={styles.pageSizerContainer}>
                    <UserSvg />
                    <span className={styles.text}> Sources per page</span>
                    <div className={styles.sizerWrapper}>
                        <PageSizer
                            currentItem={limit || '10'}
                            onClick={selectHandler}
                            array={['2', '5', '10', '20', '50']}
                        />
                    </div>
                </div>
                <PaginationComponent
                    defaultPageSize={defaultPageSize}
                    total={dist}
                    defaultCurrent={page ? Number(page) : 1}
                    onChange={onChangePagination}
                    onShowSizeChange={onShowSizeChange}
                />
            </div>
        </div>
    );
};

export default Sources;
