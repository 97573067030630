import { UserAction, UserActionTypes } from 'store/types/userActionTypes';


interface StateTyp {
    isAuth: boolean;
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    forgetPasswordEmail: string | null;
}

const initialState: StateTyp = {
    isAuth: false,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    forgetPasswordEmail: null,
};

export const userCredentials = (state = initialState, action: UserAction): StateTyp => {
    switch (action.type) {
        case UserActionTypes.START_LOADING:
            return {
                ...state,
                isLoading: true,

            };

        case UserActionTypes.USER_LOGIN:
            return {
                ...state,
                isAuth: true,
                isLoading: false,
            };
        // case UserActionTypes.REMOVE_USER_ACCOUNT:
        //     return {
        //         ...state,
        //         isAuth: false,
        //         isLoading: false,
        //     };
        case UserActionTypes.USER_FORGET_PASSWORD:
            return {
                ...state,
                forgetPasswordEmail: action.payload,
                isLoading: false,
            };
        case UserActionTypes.USER_LOGOUT:
            return {
                isAuth: false,
                isLoading: false,
                errorMessage: '',
                successMessage: '',
                forgetPasswordEmail: null,
            };
        case UserActionTypes.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload ? action.payload : '',
            };
        case UserActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                successMessage: action.payload ? action.payload : '',
            };
        case UserActionTypes.CLEAR_MESSAGE:
            return {
                ...state,
                errorMessage: '',
            };
        default:
            return state;
    }
};