import {useEffect, useRef, RefObject} from "react";

export const useClickOutside = (handler: () => void) => {
    const domNode = useRef() as RefObject<HTMLDivElement>;

    useEffect(() => {
        const maybeHandler = (event: any) => {
            if (!domNode?.current?.contains(event.target as HTMLElement)) {
                handler();
            }
        };

        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });

    return domNode;
};

