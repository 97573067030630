import React, { ForwardRefRenderFunction } from 'react';
import { ITextareaProps } from './Textarea.interface';
import styles from './Textare.module.scss';


const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, ITextareaProps> = ({ isError, Icon, ...rest }, ref) => {
    return (
        <div className={styles.container}>
            <textarea
                className={styles.textArea}
                {...rest}
                ref={ref}
            />
        </div>
    );
};
export default React.forwardRef(TextArea);
