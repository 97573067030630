import React from 'react';
import {Link} from 'react-router-dom';
import {useActions} from 'hooks/useActions';
import {useForm} from 'react-hook-form';
import Label from 'components/UI/Label';
import Input from 'components/UI/Input';
import Button from 'components/Button';
import {ILoginFormValues} from './interface';
import styles from './LogIn.module.scss';


const LogIn = () => {
    const {login} = useActions();
    const {register, handleSubmit, formState: {errors}} = useForm<ILoginFormValues>();

    const onSubmit = (data: ILoginFormValues) => {
        login({...data});
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <h1 className={styles.formTitle}>Log in</h1>
                <p className={styles.formText}>Welcome back! Please enter your details.</p>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formRow}>
                        <Label htmlFor='email' mainLabel='Email'/>
                        <div className={styles.inputWrapper}>
                            <Input
                                id='email'
                                isError={!!errors.email}
                                {...register('email', {required: true})}
                                type='email'
                                placeholder='Enter your email'

                            />
                            {errors.email && (
                                <span role='alert' className={styles.errorMessage}>This field is required </span>
                            )}
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <Label htmlFor='pass' mainLabel='Password'/>
                        <div className={styles.inputWrapper}>
                            <Input
                                id='pass'
                                isError={!!errors.password}
                                {...register('password', {
                                    required: true,
                                    pattern: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
                                })}
                                type='password'
                                placeholder='Enter your password'/>
                            {errors.password && errors.password.type === 'pattern' && (
                                <span role='alert' className={styles.errorMessage}>Password should have at least 8 characters contain a letter, one number and one special character </span>
                            )}
                            {errors.password && (
                                <span role='alert' className={styles.errorMessage}>This field is required </span>
                            )}
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.forgotPasswordWrapper}>
                            <Link to='/forgetPassword'>Forgot password</Link>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.btnContainer}>
                            <Button
                                type='submit'
                                children='Log in'/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LogIn;