import { DataSourceTypes } from "components/StackedBarChart/StackedBarChart";

  export enum HealthStatus {
    CRITICAL = 0,
    PARTIAL = 2,
    HEALTHY = 1
  }

  export const HealthStatusToNameMap = {
    '0' : HealthStatus.CRITICAL,
    '1': HealthStatus.HEALTHY,
    '2': HealthStatus.PARTIAL
  }
  
  export type StatusObject = Record<string, number>;
  
  export interface HealthReport {
    id: number;
    sourceType: DataSourceTypes;
    statusObj: StatusObject;
    overallHealth: HealthStatus;
    createdAt: string;
    updatedAt: string;
    error: {
      message: string
    }[] | null[]
  }

  export type ErrorMessagesProps = {
    errors: {
      message: string;
    }[] | null[];
  };
  
  export type HealthReports = HealthReport[];
  
  export function isHealthReport(obj: any): obj is HealthReport {
    return (
      typeof obj === 'object' &&
      typeof obj.id === 'number' &&
      Object.values(DataSourceTypes).includes(obj.sourceType) &&
      typeof obj.statusObj === 'object' &&
      Object.values(HealthStatus).includes(obj.overallHealth) &&
      typeof obj.createdAt === 'string' &&
      typeof obj.updatedAt === 'string'
    );
  }
  
  export function validateHealthReports(reports: any[]): HealthReport[] {
    return reports.filter(isHealthReport);
  }