import { toast } from 'react-toastify';


export function showSuccessNotification(text: string) {
    return toast.success(text);
}

export function showErrorNotification(text: string) {
    
    return toast.error(text);
}
