import React, { FC } from 'react';
import { useDropDown } from 'hooks/useDropDown';
import { IProfileMenuProps } from './ProfileMenu.interface';
import { useClickOutside } from 'hooks/useClickOutside';
import NoUserPic from 'assets/svg/userPicAreaCircle.svg';

import styles from './ProfileMenu.module.scss';


const ProfileMenu: FC<IProfileMenuProps> = ({ icon, children }) => {
    const { isOpen, toggleDropDown, setIsOpen } = useDropDown();


    const domNode = useClickOutside(() => {
        setIsOpen(false);
    });

    return (
        <div ref={domNode} className={styles.wrapper}>
            <button
                className={`${styles.imageContainer} ${isOpen ? styles.active : ''}`}
                onClick={toggleDropDown}
            >

                    <img src={NoUserPic} alt='avatar' />
            </button>
            {isOpen &&
                children
            }
        </div>
    );
};

export default ProfileMenu;
