import {AppDispatch} from "../index";
import {apiQuery} from "helpers/query";
import {getAnalyticsQuery, getDigestAnalyticsQuery, IAnalytics} from "api/analytics";
import {AnalyticsActionTypes} from "../types/analyticsActionTypes";
import { UserActionTypes } from "store/types/userActionTypes";


export const getAnalyticsData = ({range, start, end}: IAnalytics) => {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await apiQuery(() => getAnalyticsQuery({
                range,
                start,
                end
            }));
            try {
                const digestAnalytics = await apiQuery(() => getDigestAnalyticsQuery({
                    range,
                    start,
                    end
                }))
                data.digestAnalytics = digestAnalytics;
            } catch (error) {
                console.log(error)
            }
            dispatch({
                type: AnalyticsActionTypes.FETCHING_ANALYTICS_DATA,
                payload: data,
            });
        } catch (error) {
            if (error instanceof Error) {
                
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};