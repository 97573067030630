import React, {FC} from 'react';
import Pagination from 'rc-pagination';
import {ReactComponent as NextSvg} from 'assets/svg/nextArrow.svg';
import {ReactComponent as PrevSvg} from 'assets/svg/prevArrow.svg';
import {useViewport} from "hooks/useViewport";
import './Pagination.scss';

interface IPagination {
    defaultPageSize: number,
    defaultCurrent: number,
    total: number,
    onChange: (current: number, pageSize: number) => void,
    onShowSizeChange: (current: number, pageSize: number) => void
}

const PaginationComponent: FC<IPagination> = ({
                                                  defaultPageSize, defaultCurrent, onChange, total, onShowSizeChange
                                              }) => {
    const {isMobile} = useViewport()
    const buttonItemRender = (current: number, type: string, element: any) => {
        if (type === 'prev') {
            return <button type="button">
                <div className='prevSvg'><PrevSvg/></div>
                <span className='btnText'>Previous</span>
            </button>;
        }
        if (type === 'next') {
            return <button type="button">
                <span className='btnText'>Next</span>
                <div className='nextSvg'><NextSvg/></div>
            </button>;
        }
        return element;
    };
    return (
        <div className='paginationContainer'>
            <div className='mobile-pagination-wrapper'>
                <button className='prevSvg' onClick={() => {
                    defaultCurrent > 1 && onChange(defaultCurrent - 1, defaultPageSize)
                }}><PrevSvg/>
                </button>
                <div
                    className='paginationText'>Page {defaultCurrent} of {Math.ceil(total / defaultPageSize)}</div>
                <button className='nextSvg' onClick={() => {
                    defaultCurrent === Math.ceil(total / defaultPageSize) || onChange(defaultCurrent + 1, defaultPageSize)
                }}><NextSvg/>
                </button>
            </div>
            <Pagination
                pageSize={defaultPageSize}
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={defaultCurrent}
                onChange={onChange}
                total={total}
                itemRender={buttonItemRender}
            />
        </div>


    );
}

export default PaginationComponent;