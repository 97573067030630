import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ReactComponent as DownloadSvg } from 'assets/svg/upload.svg';
import { ReactComponent as SearchSvg } from 'assets/svg/search.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/svg/threedots.svg';
import { ReactComponent as UserSvg } from 'assets/svg/user.svg';
import { ReactComponent as LogoutSvg } from 'assets/svg/logout.svg';
import { ReactComponent as DownArrowSvg } from 'assets/svg/arrowDown.svg';
import Divider from 'components/UI/Divider';
import Table from 'components/UI/Table';
import Calendar from 'components/UI/Calendar';
import PaginationComponent from 'components/UI/Pagination';
import PageSizer from 'components/UI/PageSizer/PageSizer';
import { digestHeader, tableHeader } from 'helpers';
import NoAvatarSvg from 'assets/svg/noAvatar.svg';
import tableContainer from './TableContainer';
import CsvButton from 'components/CsvButton/CsvButton';
import styles from './Digests.module.scss';
import { digests } from 'store/reducers/Digests';

const Digests = () => {
    const { getDigestsData } = useActions();
    const { digestsData, dist } = useTypedSelector((state) => state.digests);
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/digests?sort=datedesc');
    }, []);
    const {
        onChangeHandler,
        endDate,
        selectHandler,
        logout,
        defaultPageSize,
        onShowSizeChange,
        onChangePagination,
        searchParams,
        searchDigestsHandler,
        sort,
        dd,
        page,
        limit,
        startDate,
    } = tableContainer({ getData: getDigestsData, locationParams: 'digests' });


    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h1 className={styles.mainTitle}>Digests Archive</h1>
                <p className={styles.mobileTitleSubtext}>View all digests information</p>
                <div className={styles.buttonsContainer}>
                    <div className={styles.downloadBtn}>
                        <CsvButton data={digestsData}>
                            {' '}
                            <DownloadSvg /> Download CSV
                        </CsvButton>
                    </div>
                </div>
            </div>
            <p className={styles.titleSubtext}>View the Digests created.</p>
            <div className={styles.navigationBox}>
                <nav className={styles.navContainer}>
                    <ul>
                        <li>All Digests</li>
                    </ul>
                </nav>
                <div className={styles.logoutContainer} onClick={logout}>
                    <LogoutSvg className={styles.logoutSvg} />
                    <span className={styles.text}>Log Out</span>
                </div>
            </div>

            <div className={styles.divider}>
                <Divider />
            </div>
            <div className={styles.dataCalendar}>
                <div className={styles.searchInputWrapper}>
                    <input
                        type="text"
                        defaultValue={searchParams || ''}
                        placeholder="Search for Digests by name or ID"
                        onChange={(e) => {
                            searchDigestsHandler(`${sort ? `sort=${sort}&` : ''}${dd}`, e);
                        }}
                    />
                    <div className={styles.searchIcon}>
                        <SearchSvg />
                    </div>
                </div>
                <div className={styles.calendarWrapper}>
                    <div className={styles.dataPickers}>
                        <Calendar isMaxDate startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    </div>
                </div>
            </div>
            <div className={styles.tableHeader}>
                <h2 className={styles.title}>All Digests - total {dist}</h2>
                <ThreeDotsSvg />
            </div>
            <Table>
                <thead>
                    <tr>
                        {digestHeader.map((item) => {
                            return (
                                <th key={item.id}>
                                        <div className={styles.columnHeader}>
                                        {!(item.title === 'CreatedAt') &&
                                            item.title}
                                        {item.title === 'CreatedAt' && (
                                            <Link
                                                to={`/digests?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'dateasc' ? 'dateasc' : 'datedesc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort !== 'dateasc' && sort !== 'datedesc' && sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'dateasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {digestsData.map((digest) => {
                        return (
                            <tr key={digest.digestId}>
                                 <td className={styles.digestNumberColumn}>
                                    <Link to={digest.digestId} className={styles.digestNumberColumn}>
                                        <span className={styles.name}>{digest.digestId}</span>
                                    </Link>
                                </td>
                                <td className={styles.digestNumberColumn}>{digest.name}</td>
                                 <td className={styles.digestNumberColumn}>{digest.active? "✅": "❌"}</td>
                                 {/* <td className={styles.digestNumberColumn}>{digest.buildIds.length}</td> */}
                                 <td className={styles.digestNumberColumn}>{digest.scheduleType}</td>
                                 <td className={styles.digestNumberColumn}>{digest.userEmail}</td>
                                 <td className={styles.dateColumn}>{dayjs(digest.createdAt).format('MMM DD YYYY')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.tableFooter}>
                <div className={styles.pageSizerContainer}>
                    <UserSvg />
                    <span className={styles.text}> Digests per page</span>
                    <div className={styles.sizerWrapper}>
                        <PageSizer currentItem={limit || '10'} onClick={selectHandler} array={['10', '20', '50']} />
                    </div>
                </div>
                <PaginationComponent
                    defaultPageSize={defaultPageSize}
                    total={dist}
                    defaultCurrent={page ? Number(page) : 1}
                    onChange={onChangePagination}
                    onShowSizeChange={onShowSizeChange}
                />
            </div>
        </div>
    );
};

export default Digests;
