import React from 'react';
import styles from './RedditSourceCard.module.scss';

export type RedditSourceDto = {
    name?: string;
    showHeading: boolean;
    numberOfPosts: number;
    layout: 1 | 2;
    sourceName: string;
    images: 'show' | 'hide';
    postBody: 'paragraph' | 'summary' | 'hide';
}

interface RedditSourceCardProps {
    source: RedditSourceDto; 
}

const RedditSourceCard: React.FC<RedditSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.redditCard}>
            <h2>{source.name || 'Reddit'}</h2>
            {source.showHeading && <h3>{source.sourceName}</h3>}
            <p>Number of Posts: {source.numberOfPosts}</p>
            <p>Layout: {source.layout === 1 ? 'Layout 1' : 'Layout 2'}</p>
            <p>Images: {source.images === 'show' ? 'Show' : 'Hide'}</p>
            <p>Post Body: {source.postBody === 'paragraph' ? 'Paragraph' : source.postBody === 'summary' ? 'Summary' : 'Hide'}</p>
        </div>
    );
};

export default RedditSourceCard;
