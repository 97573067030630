import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Label from 'components/UI/Label';
import Input from 'components/UI/Input';
import Button from 'components/Button';
import { useActions } from '../../hooks/useActions';
import { IForgotPasswordFormValues } from './interface';
import styles from './ForgotPassword.module.scss';


const ForgotPassword = () => {
    const navigate = useNavigate();
    const { forgetPassword } = useActions();
    const { register, handleSubmit, formState: { errors } } = useForm<IForgotPasswordFormValues>();
    const onSubmit = async (data: IForgotPasswordFormValues) => {
        await forgetPassword(data);
        navigate('/recover-message');
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.formContainer}>
                <h1 className={styles.formTitle}>Forgot Password?</h1>
                <p className={styles.formText}>Enter your details to receive a reset link</p>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formRow}>
                        <Label htmlFor='email' mainLabel='Email' />
                        <div className={styles.inputWrapper}>
                            <Input
                                type='email'
                                {...register('email')}
                                isError={!!errors.email}
                                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                                placeholder='Enter your email' />
                            {errors.email && errors.email.type === 'pattern' && (
                                <span role='alert' className={styles.errorMessage}>Should be a valid email</span>
                            )}
                            {errors.email && (
                                <span role='alert' className={styles.errorMessage}>This field is required </span>
                            )}
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.btnContainer}>
                            <Button
                                type='submit'
                                children='Send Reset Link' />
                        </div>
                    </div>
                </form>
                <div className={styles.rememberWrapper}>
                    <p className={styles.text}>Remember password?</p>
                    <Link to='/logIn'>Log in</Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;