import React, {useRef, FC} from 'react';
import Croppie from 'croppie';
import Button from '../../Button';
import {ReactComponent as UploadSvg} from 'assets/svg/upload.svg';

import {useActions} from 'hooks/useActions';
import {grayColor600, whiteColor} from 'helpers/color-variables';
import {useViewport} from 'hooks/useViewport';
import styles from './Drag.module.scss';


interface IDragAndDrop {
    setIsImage: (value: boolean) => void;
    isImage: boolean;
    userId: string,
    setIsOpened: (value: boolean) => void;
}

const DragAndDrop: FC<IDragAndDrop> = ({setIsImage, isImage, userId, setIsOpened}) => {
    const {isMobile} = useViewport();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const ref = useRef<HTMLInputElement>(null);
    const crop = useRef<Croppie | null>(null);
    const {addUserPhoto} = useActions();
    const labelElementRef = useRef<HTMLDivElement>(null);
    const name = 'archive';
    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const {
            dataTransfer: {files},
        } = e;
        const {length} = files;
        handleFile({files, length});
    };

    const handleFile = ({files, length}: { files: FileList | null; length: number | undefined }) => {
        const reader = new FileReader();

        if (length === 0 || !files) {
            return false;
        }
        reader.readAsDataURL(files[0]);

        reader.onload = () => {
            if (!crop.current) {
                crop.current = new Croppie(ref.current!, {
                    showZoomer: true,
                    enableOrientation: true,
                    mouseWheelZoom: 'ctrl',
                    viewport: {
                        width: isMobile ? 110 : 160,
                        height: isMobile ? 110 : 160,
                        type: 'circle',
                    },
                    boundary: {
                        width: isMobile ? 260 : 390,
                        height: isMobile ? 150 : 237,

                    },
                });
            }

            crop.current.bind({url: reader.result as string});
            setIsImage(true);
        };
        reader.onerror = function (error) {
            console.log('Error: ');
        };
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        labelElementRef?.current?.classList.add(styles.active);
    };
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        labelElementRef?.current?.classList.remove(styles.active);
    };
    const fileInputClicked = () => {
        fileInputRef.current && fileInputRef.current.click();
    };

    const filesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const length = e.target.files?.length;
        handleFile({files, length});

    };
    const onResult = () => {
        crop.current!.result({type: 'base64'}).then((base64) => {
            let arr: any = base64.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            const file = new File([u8arr], 'image.png', {type: mime});
            setIsImage(false);
            addUserPhoto(file, userId);

        });
    };
    const handle = () => {
        setIsImage(false);
    };

    return (
        <>
            <div ref={ref} style={{display: !isImage ? 'none' : 'block'}}></div>
            {isImage ?
                <div className={styles.Buttons}>
                    <div className={styles.BtnContainer}>
                        <Button onClick={() => {
                            onResult();
                            setIsImage(false);
                        }} backgroundColor={grayColor600} color={whiteColor}>Crop & Set Avatar</Button>
                    </div>
                    <div className={styles.BtnContainer}>
                        <Button onClick={handle} backgroundColor={whiteColor}>Upload another image</Button>
                    </div>
                </div>
                : < div className={styles.container}>

                    <div className={styles.upload} ref={labelElementRef}>
                        <div className={styles.drop} onDrop={onDrop} onDragOver={onDragOver}
                             onDragLeave={handleDragLeave}
                        >
                            <div className={styles.dropWrapper} onClick={fileInputClicked}>
                                <div className={styles.icon}>
                                    <UploadSvg/>
                                </div>
                                <div className={styles.dropMessage}>
                                    <span className={styles.dropHyperlink}> Click to upload</span>
                                    <span className={styles.dropText}>or drag and drop</span>
                                </div>
                                <p className={styles.extension}>SVG, PNG, JPG or GIF (max. 800x400px) </p>
                            </div>
                            <input
                                name={name}
                                className={styles.fileInput}
                                type='file'
                                ref={fileInputRef}
                                accept='.jpg,.png,.svg,.gif'
                                onChange={filesSelected}
                            />
                        </div>
                    </div>
                </div>}
        </>
    );
};
export default DragAndDrop;
