import React, {FC} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ICalendar} from "../UI/Calendar/Calendar";
import CustomBtn from "../UI/CustomBtn";


const AnalyticsMonthCalendar: FC<ICalendar> = ({onChange, endDate, startDate}) => {

    return (
        <DatePicker
            selected={endDate}
            onChange={onChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            customInput={React.createElement(CustomBtn)}
        />

    );
}

export default AnalyticsMonthCalendar;