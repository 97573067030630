import {combineReducers} from 'redux';
import { users } from './Users';
import {digests} from './Digests';
import {analytics} from './Analytics';
import {sources, sourcesHealth} from './Sources';
import {UserInformation} from './UserInformation';
import {userCredentials} from './UserCredentials';
import {builds} from './Builds';
import {buildInformation} from './BuildsInformation';
import {digestInformation} from './DigestsInformation';

export const rootReducer = combineReducers(
    {
        users,
        sources,
        analytics,
        digests,
        userCredentials,
        UserInformation,
        builds,
        digestInformation,
        buildInformation,
        sourcesHealth
    });

export type RootState = ReturnType<typeof rootReducer>;
