import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export enum DataSourceTypes {
    DATE = 'DATE',
    HACKER_NEWS = 'HACKER_NEWS',
    WEATHER = 'WEATHER',
    RSS = 'RSS',
    REDDIT = 'REDDIT',
    GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
    TWITTER_TWEETS = 'TWITTER_TWEETS',
    TWITTER_SEARCH = 'TWITTER_SEARCH',
    YOUTUBE = 'YOUTUBE',
    PRODUCT_HUNT = 'PRODUCT_HUNT',
    STRIPE = 'STRIPE',
    INSTAGRAM = 'INSTAGRAM',
    TIKTOK = 'TIKTOK',
    STOCK = 'STOCK',
    TEXT = 'TEXT',
    NEWSLETTER = 'NEWSLETTER',
    CRYPTO = 'CRYPTO'
}

export const options = {
  plugins: {
    title: {
        display: true,
        align: 'start' as const,
        position: 'top' as const,
        text: 'Source Build Performance Chart',
        color: '#000000',
    },
    legend: {
        display: false,
    }
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

type BuildData = {
  [key: string]: {
    [key: string]: string;
  };
};

type BuildStats = {
  successfulSourceBuilds: BuildData;
  failedSourceBuilds: BuildData;
  totalSourceBuilds: BuildData;
  sourceBuildDurations?: BuildData;
};

interface BuildStatsChartProps {
  data: BuildStats;
}

const transformData = (data: BuildStats, baseColors: { [key: string]: string }) => {
    const labels = Object.keys(data.totalSourceBuilds);
    const sourceTypes = new Set<string>();
  
    labels.forEach(date => {
      Object.keys(data.totalSourceBuilds[date] || {}).forEach(source => sourceTypes.add(source));
    });
  
    const datasets = Array.from(sourceTypes).flatMap((source, index) => {
      const totalColor = baseColors[source];
      const failedColor = generateShade(totalColor, 0.5);
      const successfulColor = generateShade(totalColor, -0.5);
      const totalBaseColor =  generateShade(totalColor, 0);
  
      return [
        {
          label: `Total ${source.replace("_", " ")} Builds`,
          data: labels.map(date => Number(data.totalSourceBuilds[date]?.[source] || 0)),
          backgroundColor: totalBaseColor,
          borderColor: totalColor, 
          stack: source,
          borderWidth: 1
        },
        {
          label: `Failed ${source.replace("_", " ")} Builds`,
          data: labels.map(date => Number(data.failedSourceBuilds[date]?.[source] || 0)),
          backgroundColor: failedColor,
          borderColor: failedColor,
          stack: source,
          borderWidth: 1
        },
        {
          label: `Successful ${source.replace("_", " ")} Builds`,
          data: labels.map(date => Number(data.successfulSourceBuilds[date]?.[source] || 0)),
          backgroundColor: successfulColor,
          borderColor: successfulColor,
          stack: source,
          borderWidth: 1
        }
      ];
    });
  
    return {
      labels,
      datasets,
    };
};
  
  const getRandomLightColor = () => {
    const letters = 'BCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };
  
  const generateShade = (color: string, percent: number) => {
    const f = parseInt(color?.slice(1),16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = f >> 8 & 0x00FF,
        B = f & 0x0000FF;
    return "#" + (0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)).toString(16)?.slice(1);
  };
  

const BuildStatsChart: React.FC<BuildStatsChartProps> = ({ data }) => {
    const baseColors: { [key in DataSourceTypes]: string } = {
        [DataSourceTypes.DATE]: '#FFEBCC',
        [DataSourceTypes.HACKER_NEWS]: '#CCFFEB',
        [DataSourceTypes.WEATHER]: '#EBCCFF',
        [DataSourceTypes.RSS]: '#FFF2CC',
        [DataSourceTypes.REDDIT]: '#FFE0CC',
        [DataSourceTypes.GOOGLE_CALENDAR]: '#CCFFFF',
        [DataSourceTypes.TWITTER_TWEETS]: '#F5CCFF',
        [DataSourceTypes.TWITTER_SEARCH]: '#FFCCF5',
        [DataSourceTypes.YOUTUBE]: '#FFD8B1',
        [DataSourceTypes.PRODUCT_HUNT]: '#D2B48C',
        [DataSourceTypes.STRIPE]: '#B2FFFF',
        [DataSourceTypes.INSTAGRAM]: '#BEBEBE',
        [DataSourceTypes.TIKTOK]: '#FFC0CB',
        [DataSourceTypes.TEXT]: '#98FB98',
        [DataSourceTypes.NEWSLETTER]: '#98FB08',
        [DataSourceTypes.CRYPTO]: '#98FB08',
        [DataSourceTypes.STOCK]: '#98FB08',
      };
  const chartData = transformData(data, baseColors);

  return <Bar options={options} data={chartData} />;
};

export default BuildStatsChart;
