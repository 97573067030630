import React, { ForwardRefRenderFunction } from 'react';
import { IInputProps } from './Input.interface';
import styles from './Input.module.scss';


const Input: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = ({ isError, Icon, type, ...rest }, ref) => {
    const additionalStyle = {
        pop: {
            paddingLeft: Icon ? '41px' : '14px',
        },
    };
    return (
        <div className={styles.container}>
            {Icon && <div className={styles.icon}><Icon /></div>}
            <input
                ref={ref}
                {...rest}
                type={type}
                className={`${styles.input} ${isError ? styles.error : ''}`}
                style={additionalStyle.pop}
            />
        </div>
    );
};

export default React.forwardRef(Input);
