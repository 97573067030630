import axios, { errorCallBack } from './axios';
import { ILoginFormValues } from 'pages/LogIn/interface';

type LoginProps = {
    status: number,
    message: string,
    token: {
        'refreshToken': string,
        'accessToken': string,
    }
}

type LogoutProps = {
    message: string;
}

export const loginQuery = ({ email, password }: ILoginFormValues): Promise<LoginProps> => {
    return axios.post(`/admin/auth/signin/email`, {
        email,
        password,
    }).then(({ data }) => data).catch(errorCallBack);
};

export const logoutQuery = async (): Promise<{ data: LogoutProps }> => {
    return axios.get(`/admin/auth/logout`).catch(errorCallBack);
};
