import React, { useEffect, useState } from 'react';
import { ReactComponent as EmailSvg } from 'assets/svg/email.svg';
import { ReactComponent as ClockSvg } from 'assets/svg/clock.svg';
import NoAvatarSvg from 'assets/svg/noAvatar.svg';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { AllTimeZonesProps } from 'components/UI/Select/Select.type';
import { useActions } from 'hooks/useActions';
import { useParams } from 'react-router-dom';
import Label from 'components/UI/Label';
import Input from 'components/UI/Input/Input';
import Divider from 'components/UI/Divider';
import Select from 'components/UI/Select/Select';
import DragAndDrop from 'components/UI/DragAndDrop/DragAndDrop';
import Button from 'components/Button';
import { allTimeZones } from 'helpers';
import { grayColor600, whiteColor } from 'helpers/color-variables';
import { UserEditProfileProps, UserProps } from 'api/types';
import { useTypedSelector } from 'hooks/useTypedSelector';
import TextArea from 'components/UI/Textarea/TextArea';
import styles from './Details.module.scss';
// import { getUserQuery } from 'api/users';
import { getUserData } from 'store/action-creators/UserInformation';
import { getUserQuery } from 'api/users';

const Details = () => {
    const { editUserData } = useActions();
    const { userId } = useParams();
    const [currentTimezone, setCurrentTimezone] = useState<AllTimeZonesProps>({ offset: '', timeZone: '' });
    const {
        userData,
    } = useTypedSelector((state) => state.UserInformation);
    // const [fetchedUserData, setFetchedUserData] = useState<UserProps>();
    const { register, handleSubmit, setValue } = useForm<UserEditProfileProps>();
    const [modalOpenStatus, setModalOpenStatus] = useState({
        image: false,
        account: false,
        dragAndDrop: false,
        deleteImage: false,
    });

    const userPromise = getUserQuery(userId as string);
    
    useEffect(() => {
        userPromise
            .then((userData) => { 
                // setFetchedUserData(userData)
                setCurrentTimezone(
                    {
                        offset: `(GMT ${dayjs().tz(userData.timezone as unknown as string).format('Z')})`,
                        timeZone: userData.timezone as unknown as string,
                    }
                );
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, []);

    useEffect(() => {      
        setValue('email', userData.email);
        setValue('fullName', userData.fullName);
        setValue('username', userData.username);
        setValue('bio', userData.bio);
    }, [userData]);

        
    const selectHandler = (time: AllTimeZonesProps | string) => {
        setCurrentTimezone(time as AllTimeZonesProps);        
    };

    // useEffect(() => {
    //     setCurrentTimezone(
    //         timezone
    //             ? {
    //                   offset: `(GMT ${dayjs().tz(timezone).format('Z')})`,
    //                   timeZone: timezone,
    //               }
    //             : {
    //                   offset: `(GMT ${dayjs().tz().format('Z')})`,
    //                   timeZone: dayjs.tz.guess(),
    //               },
    //     );
    // }, [timezone]);    

    const onSubmit = ({ ...data }: UserEditProfileProps) => {
        const formData: {
            userId?: string | undefined;
            email?: string | undefined;
            username?: string | undefined;
            fullName?: string | undefined;
            profileImg?: string | undefined;
            bio?: string | undefined;
            timezone?: string;
            } = {
                ...(userData?.timezone === currentTimezone.timeZone
                    ? {}
                    : {
                        timezone: currentTimezone.timeZone
                    }),
                ...Object.fromEntries(
                    Object.entries(data).filter(([key, value]) => userData && userData[key as keyof typeof userData] !== value),
                ),
        };
        delete formData?.bio;
        
        if (Object.keys(formData).length !== 0) {
            userId && editUserData(userId, { ...formData });
        }
    };

    return (
        <>
            <div className={styles.container}>
                <form className={styles.detailsForm} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.userInfoInputs}>
                        <div className={styles.inputWrapper}>
                            <Label mainLabel="Full name" />
                            <Input id="fullName" type="text" {...register('fullName')} />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Label mainLabel="Username" />
                            <Input id="username" type="text" {...register('username')} />
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.inputWrapper}>
                        <Label mainLabel="Email" />
                        <Input id="email" type="email" Icon={EmailSvg} {...register('email')} />
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.selectWrapper}>
                        <Label mainLabel="Timezone" />
                        <Select
                            array={allTimeZones}
                            currentItem={currentTimezone}
                            onClick={selectHandler}
                            Icon={ClockSvg}
                        />
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.uploadPicture}>
                        <div className={styles.userImage}>
                            {userData?.profileImg ? (
                                <img src={userData.profileImg} alt="avatar" />
                            ) : (
                                <img src={NoAvatarSvg} alt="no-avatar" />
                            )}
                        </div>
                        <div className={modalOpenStatus.image ? '' : styles.dragAndDropWrapper}>
                            <DragAndDrop
                                userId={userData?.userId as string}
                                setIsImage={(value: boolean) => {
                                    setModalOpenStatus({ ...modalOpenStatus, image: value });
                                }}
                                setIsOpened={(value: boolean) => {
                                    setModalOpenStatus({ ...modalOpenStatus, dragAndDrop: value });
                                }}
                                isImage={modalOpenStatus.image}
                            />
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.textareaWrapper}>
                        <Label mainLabel="Signup source" />
                        <p>{userData?.method}</p>
                    </div>
                    <div className={styles.textareaWrapper}>
                        <Label mainLabel="Bio" />
                        <TextArea id="bio" type="text" {...register('bio')} />
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <div className={styles.cancelBtn}>
                            <Button children="Cancel" backgroundColor={whiteColor} />
                        </div>
                        <div className={styles.saveBtn}>
                            <Button children="Save" type="submit" backgroundColor={grayColor600} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Details;
