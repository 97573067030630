import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ReactComponent as DownloadSvg } from 'assets/svg/upload.svg';
import { ReactComponent as SearchSvg } from 'assets/svg/search.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/svg/threedots.svg';
import { ReactComponent as UserSvg } from 'assets/svg/buildLogs.svg';
import { ReactComponent as LogoutSvg } from 'assets/svg/logout.svg';
import { ReactComponent as DownArrowSvg } from 'assets/svg/arrowDown.svg';
import Divider from 'components/UI/Divider';
import Table from 'components/UI/Table';
import Calendar from 'components/UI/Calendar';
import PaginationComponent from 'components/UI/Pagination';
import PageSizer from 'components/UI/PageSizer/PageSizer';
import { buildsHeader } from 'helpers';
import tableContainer from './TableContainer';
import CsvButton from 'components/CsvButton/CsvButton';
import styles from './Builds.module.scss';
import { builds } from 'store/reducers/Builds';

const Builds = () => {
    const {  getBuildsData } = useActions();
    const { buildData, dist } = useTypedSelector((state) => state.builds);
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/builds?sort=datedesc');
    }, []);
    const {
        onChangeHandler,
        endDate,
        selectHandler,
        logout,
        defaultPageSize,
        onShowSizeChange,
        onChangePagination,
        searchParams,
        searchBuildsHandler,
        sort,
        dd,
        page,
        limit,
        startDate,
    } = tableContainer({ getData: getBuildsData, locationParams: 'builds' });


    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h1 className={styles.mainTitle}>Builds Archive</h1>
                <p className={styles.mobileTitleSubtext}>View all digest build information</p>
                <div className={styles.buttonsContainer}>
                    <div className={styles.downloadBtn}>
                        <CsvButton data={buildData}>
                            {' '}
                            <DownloadSvg /> Download CSV
                        </CsvButton>
                    </div>
                </div>
            </div>
            <p className={styles.titleSubtext}>View the builds run.</p>
            <div className={styles.navigationBox}>
                <nav className={styles.navContainer}>
                    <ul>
                        <li>All Builds</li>
                    </ul>
                </nav>
                <div className={styles.logoutContainer} onClick={logout}>
                    <LogoutSvg className={styles.logoutSvg} />
                    <span className={styles.text}>Log Out</span>
                </div>
            </div>

            <div className={styles.divider}>
                <Divider />
            </div>
            <div className={styles.dataCalendar}>
                <div className={styles.searchInputWrapper}>
                    <input
                        type="text"
                        defaultValue={searchParams || ''}
                        placeholder="Search for Builds"
                        onChange={(e) => {
                            searchBuildsHandler(`${sort ? `sort=${sort}&` : ''}${dd}`, e);
                        }}
                    />
                    <div className={styles.searchIcon}>
                        <SearchSvg />
                    </div>
                </div>
                <div className={styles.calendarWrapper}>
                    <div className={styles.dataPickers}>
                        <Calendar isMaxDate startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    </div>
                </div>
            </div>
            <div className={styles.tableHeader}>
                <h2 className={styles.title}>All Builds - total {dist}</h2>
                <ThreeDotsSvg />
            </div>
            <Table>
                <thead>
                    <tr>
                        {buildsHeader.map((item) => {
                            return (
                                <th key={item.id}>
                                    <div className={styles.columnHeader}>
                                        {!(item.title === 'Build Number' || item.title === 'CreatedAt') &&
                                            item.title}
                                        {item.title === 'Build Number' && (
                                            <Link
                                                to={`/builds?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'buildnumberasc' ? 'buildnumberdesc' : 'buildnumberasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort !== 'buildnumberasc' && sort !== 'buildnumberdesc' && sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'buildnumberasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                        {item.title === 'CreatedAt' && (
                                            <Link
                                                to={`/builds?${searchParams ? `search=${searchParams}&` : ''}sort=${
                                                    sort === 'dateasc' ? 'datedesc' : 'dateasc'
                                                }&${dd}`}
                                            >
                                                {item.title}
                                                {sort !== 'dateasc' && sort !== 'datedesc' && sort && (
                                                    <span
                                                        className={`${styles.svg} ${
                                                            sort === 'dateasc' ? styles.arrowSvg : ''
                                                        }`}
                                                    >
                                                        <DownArrowSvg />
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {buildData && buildData.map((build) => {
                        return (
                            <tr key={build.buildId}>
                                 <td className={styles.digestNumberColumn}>
                                    <Link to={build.buildId} className={styles.digestNumberColumn}>
                                        <span className={styles.name}>{build.buildId}</span>
                                    </Link>
                                </td>
                                <td className={styles.digestNumberColumn}>{build.buildNumber}</td>
                                <td className={styles.digestNumberColumn}>{build.userEmail}</td>
                                <td className={styles.digestNumberColumn}>{build.emailsExpected.length}</td>
                                <td className={styles.digestNumberColumn}>{build.emailsSent.length}</td>
                                <td className={styles.dateColumn}>{dayjs(build.createdAt).format('MMM DD YYYY')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.tableFooter}>
                <div className={styles.pageSizerContainer}>
                    <UserSvg />
                    <span className={styles.text}> Logs per page</span>
                    <div className={styles.sizerWrapper}>
                        <PageSizer currentItem={limit || '10'} onClick={selectHandler} array={['10', '20', '50']} />
                    </div>
                </div>
                <PaginationComponent
                    defaultPageSize={defaultPageSize}
                    total={dist}
                    defaultCurrent={page ? Number(page) : 1}
                    onChange={onChangePagination}
                    onShowSizeChange={onShowSizeChange}
                />
            </div>
        </div>
    );
};

export default Builds;
