import styles from './BullDashboard.module.scss';
import { getState } from 'helpers/LocalStorage';
const API_URL = process.env.REACT_APP_API;

const BullDashboard = () => {
    const token = getState('admin_token');
    const url = `${API_URL}/admin/server/proxy?token=${token.accessToken}`
    return (
        <div className={styles.wrapper}>
            <iframe
                src={url}
                className={styles.iframe}
                title="Bull Dashboard"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
        </div>
    );
};

export default BullDashboard;
