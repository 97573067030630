import {AnalyticsActionTypes, AnalyticsAction} from "../types/analyticsActionTypes";


interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    analytics: any
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    analytics: {
            clicks: 0,
            complaints:0,
            deliveries:0,
            opens: 0,
            rejects:0,
            sends:0,
            signUps:0,
            usersWithDigests: 0,
            buildCreationTrend: 0,
            buildsFailed: 0,
            buildsSuccessful: 0,
            buildsProcessing: 0,
            digestBuilds: 0,
            avgBuildDurationInMilliseconds: 0,
            emailCompletion: 0,
            buildTrend: 0,
            avgBuildDurationInseconds: 0,
    },
};

export const analytics = (state = initialState, action: AnalyticsAction): StateTyp => {
    switch (action.type) {
        case AnalyticsActionTypes.FETCHING_ANALYTICS_DATA:
            return {
                ...state,
                isLoading: true,
                analytics: action.payload
            };

            case AnalyticsActionTypes.FETCH_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    errorMessage: action.payload ? action.payload : '',
                };
            

        default:
            return state;
    }
};