import React, { FC } from 'react';
import styles from './Label.module.scss';

interface ILabel {
    Icon?: React.ElementType,
    mainLabel: string,
    subLabel?: string
    htmlFor?: string
}

const Label: FC<ILabel> = (props) => {
    const { Icon, mainLabel, subLabel, htmlFor } = props;
    return (
        <label htmlFor={htmlFor} className={styles.container}>
            <div className={styles.mainLabel}>
                <p className={styles.mainText}>{mainLabel} </p>
                {Icon && <div className={styles.icon}><Icon /></div>}
            </div>


            {subLabel && <p className={styles.subLabel}>{subLabel}</p>}

        </label>
    );
};

export default Label;