import React, { FC } from 'react';
import { ReactComponent as HamburgerMenuSvg } from 'assets/svg/hamburger.svg';
import { ReactComponent as CloseSvg } from 'assets/svg/cross.svg';
import { IMenuProps } from './Menu.interface';
import styles from './Hamburger.module.scss';


const HamburgerMenu: FC<IMenuProps> = ({ onClick, show }) => {

    return (
        <div className={styles.iconWrapper} onClick={onClick}>
            {show ? <HamburgerMenuSvg /> : <CloseSvg />}
        </div>
    );
};

export default HamburgerMenu;