import {AppDispatch} from 'store';
import {apiQuery} from 'helpers/query';
import { DigestsActionTypes } from 'store/types/digestsActionTypes';
import { getDigest } from 'api/digests';


export interface IGetDigestDataParams {
    digestId: string
}


export const getDigestData = ({ digestId }: IGetDigestDataParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const digestDetails = await apiQuery(() => getDigest(digestId));

            dispatch({
                type: DigestsActionTypes.DIGESTS_INFORMATION,
                payload: digestDetails,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: DigestsActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};


