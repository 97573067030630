import {AppDispatch} from 'store';
import {apiQuery} from 'helpers/query';
import {UserActionTypes} from 'store/types/userActionTypes';
import {getSourcesQuery, getUsersQuery, setAllToFreeTrialQuery, uploadFile, uploadRSSLinksQuery} from 'api/users';


export interface IGetUsersDataParams {
    searchParams: string | null,
    limit: string | null,
    sort: string | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    page: string | null,
}


export const getUsersData = ({searchParams, sort, page, dateFrom, dateTo, limit}: IGetUsersDataParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const {users, dist} = await apiQuery(() => getUsersQuery({
                searchParams,
                page,
                sort,
                limit,
                dateFrom,
                dateTo
            }));

            dispatch({
                type: UserActionTypes.FETCHING_DATA,
                payload: {users, dist},
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const getSourcesData = ({searchParams, sort, page, dateFrom, dateTo, limit}: IGetUsersDataParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const {sources, dist} = await apiQuery(() => getSourcesQuery({
                searchParams,
                sort,
                page,
                dateFrom,
                dateTo,
                limit
            }));
            dispatch({
                type: UserActionTypes.FETCHING_SOURCES,
                payload: {sources, dist},
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const uploadRSSURL = (fileURL: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const resp = await apiQuery(() => uploadRSSLinksQuery(fileURL));
            return resp
            
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const addRSSFile = (file: File) => {
    return async (dispatch: AppDispatch) => {
        try {
            const {url} = await apiQuery(() => uploadFile(file));
           return url
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const setAllToFreeTrial = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const { message } = await apiQuery(() => setAllToFreeTrialQuery());
            dispatch({
                type: UserActionTypes.SET_ALL_TO_FREE_TRIAL,
                message: message
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

