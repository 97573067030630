import React, {FC} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ICalendar} from "../UI/Calendar/Calendar";
import CustomBtn from "../UI/CustomBtn";


const AnalyticsYearCalendar: FC<ICalendar> = ({onChange, endDate, startDate}) => {
    return (
        <DatePicker
            selected={endDate}
            onChange={onChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy"
            showYearPicker
            customInput={React.createElement(CustomBtn)}
        />
    );
}

export default AnalyticsYearCalendar;