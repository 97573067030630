import { ChartOptions } from 'chart.js';
import React from 'react';
import { Scatter } from 'react-chartjs-2';

interface ScatterData {
  datasets: {
    label: string;
    data: { x: number; y: number }[];
    backgroundColor: string;
  }[];
}

interface ScatterPlotProps {
  data: ScatterData;
}

const ScatterPlot: React.FC<ScatterPlotProps> = ({ data }) => {
  const options: ChartOptions<'scatter'> = {
    plugins: {
      title: {
          display: true,
          align: 'start' as const,
          position: 'top' as const,
          text: 'Digest Schedule Scatter Plot',
          color: '#000000',
      },
      legend: {
          display: false,
      }
    },
    responsive: true,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Digest Schedule Time',
        },
        ticks: {
          callback: (value: string | number) => {
            const minutes = typeof value === 'string' ? parseInt(value, 10) : value;
            const hours = Math.floor(minutes / 60);
            const minutesRemainder = minutes % 60;
            return `${hours.toString().padStart(2, '0')}:${minutesRemainder.toString().padStart(2, '0')}`;
          },
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Digest Count',
        },
      },
    },
  };

  return <Scatter data={data} options={options} />;
};

export default ScatterPlot;
