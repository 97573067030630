import {UserAction, UserActionTypes} from 'store/types/userActionTypes';
import {TimezoneProps, UserDataProps} from 'api/types';

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    userData: UserDataProps,
    timezone: string;
    isEditLoading: boolean,
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    isEditLoading: false,
    successMessage: '',
    userData: {
        userId: '',
        username: '',
        fullName: '',
        method: '',
        email: '',
        bio: '',
        confirmed: false,
        profileImg: '',
        updatedAt: '',
        createdAt: '',

    },
    timezone: ''
};


export const UserInformation = (state = initialState, action: UserAction): StateTyp => {
    switch (action.type) {
        case UserActionTypes.START_EDIT_LOADING:
            return {
                ...state,
                isEditLoading: true,
            };
        case UserActionTypes.SET_USER_IMAGE:
            return {
                ...state,
                userData: {...state.userData, ...action.payload},
                isLoading: false,
                isEditLoading: false,
            };
        case UserActionTypes.SET_USER_DATA:
            const {timezone, ...data} = action.payload;
            return {
                ...state,
                userData: {...state.userData, ...data},
                timezone: timezone ? timezone : state.timezone,
                isLoading: false,
                isEditLoading: false,
            };
        default:
            return state;
    }
};