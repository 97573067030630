import { Digest } from 'api/types';
import {DigestsActionTypes, DigestsAction} from 'store/types/digestsActionTypes';

export interface DigestsProps {
    logId: string,
    status: string,
    description: string,
    completion_value: object | null,
    createdAt: string,
    digest_id: string,
    build_id: string
}

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    dist: number,
    digestData: Digest,
    type: string
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    dist: 0,
    digestData: {
        digestId: '',
        name: '',
        active: false,
        scheduleType: '',
        user: {
            userId: '',
            email: '',
            userEmail: '',
            userProfileImg: {
                key: '',
                bucket: '',
                location: ''
            }
        },
        builds: [],
        subscribers: [],
        time: '',
        isPublic: false,
        sources: [],
        createdAt: '',
        updatedAt: ''
    },
    type: ''
};


export const digestInformation = (state = initialState, action: DigestsAction): StateTyp => {
    switch (action.type) {
        case DigestsActionTypes.DIGESTS_INFORMATION:
            return {
                ...state,
                isLoading: true,
                digestData: {...action.payload.digest},
            };
    
        default:
            return state;
    }
};