import React, {FC, ReactNode} from 'react';
import styles from './Table.module.scss';

interface ITable {
    children: ReactNode
}

const Table: FC<ITable> = ({children}) => {

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                {children}
            </table>
        </div>

    );
}

export default Table;