import * as Users from './Users';
import * as UserInformation from './UserInformation';
import * as Digests from './Digests'
import * as Authentication from './Auth';
import * as Analytics from './Analytics';
import * as Builds from './Builds';
import * as BuildInformation from './BuildsInformation'
import * as Digest from './DigestInformation'

const Actions = {...Users, ...UserInformation, ...Authentication, ...Analytics, ...Digests, ...Digest, ...Builds, ...BuildInformation};
export default Actions;