import React, {ForwardRefRenderFunction} from 'react';
import styles from '../Calendar/Calendar.module.scss';
import {ReactComponent as CalendarSvg} from 'assets/svg/calendar.svg';

interface IBtn {
    value: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

const CustomBtn: ForwardRefRenderFunction<HTMLButtonElement, IBtn> = ({onClick, value}, ref) => {
    return (
        <button
            className={styles.calendar}
            ref={ref}
            onClick={onClick}>
            <CalendarSvg/> {value || "-- -- ---- -- -- ----"}
        </button>
    );
};
export default React.forwardRef(CustomBtn);