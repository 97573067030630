import React from 'react';
import AccountCard from "components/AccountCard";
import styles from './Accounts.module.scss'

const Accounts = () => {
    return (
        <div className={styles.wrapper}>
            <AccountCard connected={true}/>
            <AccountCard connected={true}/>
            <AccountCard/>
            <AccountCard/>
            <AccountCard/>
        </div>
    );
}

export default Accounts;