import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as DropDownSvg } from 'assets/svg/dropwDown.svg';
import { ReactComponent as YellowCheckSvg } from 'assets/svg/yellowCheck.svg';
import { useClickOutside } from 'hooks/useClickOutside';

import styles from './Select.module.scss';
import { ISelect } from './Select.type';


const Select: FC<ISelect> = ({ currentItem, onClick, Icon, array }) => {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState('');

    const handler = () => {
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        setCurrent(`${currentItem.offset} ${currentItem.timeZone}`);
    }, [currentItem]);

    const domNode = useClickOutside(() => {
        setOpen(false);
    });

    return (
        <div className={styles.container} ref={domNode}>
            <div className={`${styles.selectBtn} ${open ? styles.active : ''}`} onClick={handler}>
                <div className={styles.clockSvg}>
                    {Icon && <Icon />}

                    <span className={styles.btnText}>{current}</span>
                </div>
                <div className={styles.arrowDown}>
                    <DropDownSvg />
                </div>
            </div>

            <div className={styles.listItems}>
                {array.map((item, index) => {
                    const timezone = typeof item === 'object' ? item.timeZone : item;
                    return (
                        <li
                            className={`${styles.item} ${timezone === currentItem.timeZone ? styles.checked : ''}`}
                            key={timezone + index}
                            onClick={() => {
                                setTimeout(() => {
                                    onClick(item);
                                    setCurrent(timezone);
                                    handler();
                                }, 165);
                            }}
                        >
                            <span
                                className={styles.itemText}>{typeof item === 'object' ? `(GMT ${item.offset}) ${item.timeZone}` : item}</span>
                            <span className={styles.checkMark}>
                                <YellowCheckSvg />
                            </span>
                        </li>
                    );
                })}
            </div>
        </div>
    );
};

export default Select;
