import React from 'react';
import styles from './StripeSourceCard.module.scss';

export type StripeAccountDto = {
    accountName: string;
    balance: number;
    currency: string;
}

export type StripeSourceDto = {
    name?: string;
    showHeading: boolean;
    stripeAccounts: StripeAccountDto[];
}

interface StripeSourceCardProps {
    source: StripeSourceDto; 
}

const StripeSourceCard: React.FC<StripeSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.stripeCard}>
            <h2>{source.name || 'Stripe Accounts'}</h2>
            {source.showHeading && <h3>Accounts</h3>}
            {source.stripeAccounts.map((account, index) => (
                <div key={index} className={styles.account}>
                    <h4>{account.accountName}</h4>
                    <p>Balance: {account.balance} {account.currency.toUpperCase()}</p>
                </div>
            ))}
        </div>
    );
};

export default StripeSourceCard;
