import React, { Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import MainWrapper from '../UI/MainWrapper';
import { showErrorNotification, showSuccessNotification } from 'helpers/notifications';
import styles from './Layout.module.scss';
import { useTypedSelector } from 'hooks/useTypedSelector';
import dayjs from 'dayjs';
import { useActions } from 'hooks/useActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const memoizedError = () => {
    let cache = '';
    let date = dayjs();
    return (message: string) => {
        if (message) {
            date = dayjs();
            cache = message;
            showErrorNotification(message);
        }
    };
};
const error = memoizedError();

const Layout = () => {
    const { errorMessage } = useTypedSelector((state) => state.userCredentials);

    const { clearError, setSuccess } = useActions();

    useEffect(() => {
        error(errorMessage);
        return () => {
            clearError();
        };
    }, [errorMessage]);

    return (
        <Fragment>
            <Header />
            <MainWrapper>
                <ToastContainer />
                <div className={styles.content}>
                    <Outlet />
                </div>
            </MainWrapper>
        </Fragment>
    );
};

export default Layout;
