import axios from 'axios';
import {getState} from 'helpers/LocalStorage';
import {AxiosError} from '../types';

const API_URL = process.env.REACT_APP_API;

const API = axios.create({
    baseURL: `${API_URL}`,
    timeout: 10000,
    headers: {},
});

API.interceptors.request.use(
    config => {
        const token = getState('admin_token');
        if (token) {
            config.headers!.Authorization = `Bearer ${token.accessToken}`;
        } else {
            delete API.defaults.headers.common.Authorization;
        }
        return config;
    },

    error => Promise.reject(error),
);

export const errorCallBack = (err: AxiosError) => {
    if (err.response.data.status === 401) throw err;
    throw new Error(err.response.data.message);
};

export default API;


