import {UserAction, UserActionTypes} from 'store/types/userActionTypes';

export interface UsersProps {
    email: string,
    userId: string,
    fullName: string,
    method:string,
    usedSources: number,
    createdAt: string,
    sources: string[],
    digestNumbers: number,
    userMailNumbers: number,
    profileImg?: any;
    subscriptionPlan?: string;
}

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    dist: number,
    usersData: UsersProps[]
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    dist: 0,
    usersData: [],
};


export const users = (state = initialState, action: UserAction): StateTyp => {
    switch (action.type) {
        case UserActionTypes.FETCHING_DATA:
            return {
                ...state,
                isLoading: true,
                dist: action.payload.dist,
                usersData: [...action.payload.users],
            };
        case UserActionTypes.SET_ALL_TO_FREE_TRIAL:
            return {
                ...state,
                isLoading: true,
                successMessage: action.message,
            };

        default:
            return state;
    }
};