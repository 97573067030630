import axios, {errorCallBack} from "./axios";

export interface IAnalytics {
    range: string | null,
    start: string | null,
    end: string | null
}

export const getAnalyticsQuery = ({range, start, end}: IAnalytics): Promise<any> => {
    return axios.get(`/admin/analytics?${range ? `range=${range}&` : ""}${start ? `start=${start}&` : ""}${end ? `end=${end}` : ""}`).then(({data}) => data).catch(errorCallBack);
};

export const getAnalyticsMonthlyQuery = (): Promise<any> => {
    return axios.get(`/admin/analytics?range=monthly`).then(({data}) => data).catch(errorCallBack);
};

export const getDigestAnalyticsQuery = ({range, start, end}: IAnalytics): Promise<any> => {
    return axios.get(`/admin/digests/analytics?${`interval=30&`}${range ? `range=${range}&` : ""}${start ? `start=${start}&` : ""}${end ? `end=${end}` : ""}`).then(({data}) => data).catch(errorCallBack);
};