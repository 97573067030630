import React from 'react';
import { Card, Tag } from 'antd';
import { AlertCircle, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';
import { ErrorMessagesProps, HealthReport, HealthStatus, HealthStatusToNameMap } from './health-report-types';
import './HealthDashboard.scss';

interface HealthReportComponentProps {
  healthReports: HealthReport[];
}

const HEALTH_STATUS_MAP = { 
    [HealthStatus.CRITICAL]: { 
        color: 'text-red', icon: XCircle, label: 'Critical'  
    }, 
    [HealthStatus.PARTIAL]: { 
        color: 'text-yellow', icon: AlertTriangle, label: 'Partial'  
    }, 
    [HealthStatus.HEALTHY]: { 
        color: 'text-green', icon: CheckCircle, label: 'Healthy'  
    } 
}; 

const renderStatusDetails = (statusObj: Record<string, number>) => {
  return Object.entries(statusObj).map(([key, value]) => {
    const statusColor = value === 1 ? 'tag-green' : 'tag-red';
    return (
      <div key={key} className="status-details">
        <span>
          <strong>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).replace('Hacker News', 'HN')}</strong>
        </span>
        <Tag className={statusColor}>{value === 1 ? '🟢' : '🔴'}</Tag>
      </div>
    );
  });
};

const ErrorMessages: React.FC<ErrorMessagesProps> = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className="error-messages">
      {errors.map((error, index) => (
        error && (
          <div 
            key={`error-${index}`}
            className="error-message"
          >
            {error.message}
          </div>
        )
      ))}
    </div>
  );
};

const HealthReportComponent: React.FC<HealthReportComponentProps> = ({ healthReports }) => {
  return (
    <div className="health-dashboard">
      <div className="health-grid">
        {healthReports.map((report, index) => {
          const HealthIcon = HEALTH_STATUS_MAP[report.overallHealth]?.icon || AlertCircle;
          const healthColor = HEALTH_STATUS_MAP[report.overallHealth]?.color || 'text-gray';
          const healthLabel = HEALTH_STATUS_MAP[report.overallHealth]?.label || 'Unknown';

          return (
            <Card
              key={index}
              title={report.sourceType.replace(/_/g, ' ')}
              extra={<Tag className={healthColor}>{healthLabel}</Tag>}
              className="health-card"
            >
              <div className="card-content">
                <div className={`status-row ${healthColor}`}>
                  <HealthIcon className="status-icon" />
                  <span>Status: {healthLabel}</span>
                </div>
                {renderStatusDetails(report.statusObj)}
              </div>
              <ErrorMessages errors={report.error} />
              <div className="last-updated">
                Last Updated: {new Date(report.updatedAt).toLocaleString()}
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default HealthReportComponent;
