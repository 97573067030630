import React, {useEffect, useState} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {ReactComponent as Logo} from 'assets/svg/logo.svg';
import ProfilePic from 'assets/images/Avatar.png';
import {navLinks} from 'helpers';
import {getState} from 'helpers/LocalStorage';
import {useClickOutside} from 'hooks/useClickOutside';
import CustomLink from '../UI/CustomLink/CustomLink';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import HamburgerMenu from '../UI/HamburgerMenu';
import SubMenu from '../UI/SubMenu/SubMenu';

import styles from './Header.module.scss';

const Header = () => {
    const {pathname} = useLocation();
    const [show, setShow] = useState(true);
    const token = getState('admin_token');

    useEffect(() => {
        setShow(true);
    }, [pathname]);

    const handleChangeIcon = () => {
        return setShow((prev) => !prev);
    };
    const domNode = useClickOutside(() => {
        setShow(true);
    });

    return (
        <>
            <header className={styles.container}>
                <div className={styles.menuWrapper}>
                    <div className={styles.logoContainer}>
                        <Link to='/'>
                            <Logo/>
                        </Link>
                    </div>
                    {token?.accessToken ?
                        <>
                            <nav className={styles.navigationContainer}>
                                <ul>
                                    {navLinks.map(link => (

                                        <CustomLink key={link.id} to={link.route}>{link.navLink}</CustomLink>
                                    ))}
                                </ul>
                            </nav>
                            <div className={styles.profileContainer}>
                                <ProfileMenu icon={ProfilePic}></ProfileMenu>
                            </div>
                            <div className={styles.toggleWrapper}>
                                <HamburgerMenu show={show} onClick={handleChangeIcon}/>
                            </div>

                        </>
                        : ''}
                </div>
            </header>
            {!show && (
                <div className={styles.menu}>
                    <SubMenu>
                        <div className={styles.mobileMenuContainer} ref={domNode}>
                            <nav className={styles.mobileNavigationContainer}>
                                <ul>
                                    {navLinks.map((link) => (
                                        <CustomLink key={link.id} to={link.route}>
                                            {link.navLink}
                                        </CustomLink>
                                    ))}
                                </ul>
                            </nav>
                            <div className={styles.userInfo}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.userMobileAvatarContainer}>
                                        {/*{userData.profileImg ? (*/}
                                        {/*    <img src={userData.profileImg} alt="avatar" />*/}
                                        {/*) : (*/}
                                        {/*    <img src={NoUserPic} alt="avatar" />*/}
                                        {/*)}*/}
                                    </div>
                                    <div className={styles.userMobileInfo}>
                                        <p className={styles.userName}>Olivia Rhye</p>
                                        <p className={styles.userEmail}>olivia@untitledui.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SubMenu>
                </div>
            )}
        </>
    );
};

export default Header;