import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DeleteSvg } from 'assets/svg/delete.svg';
import NoAvatarSvg from 'assets/svg/noAvatar.svg';
import Button from 'components/Button';
import Modal from 'components/UI/Modal';
import Divider from 'components/UI/Divider';
import { whiteColor, blackColor, redColor } from 'helpers/color-variables';
import { subNavLinks } from 'helpers';
import CustomLink from 'components/UI/CustomLink';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useActions } from 'hooks/useActions';
import { setCookie } from 'helpers/setCookie';
import { deleteUserQuery, syncUserSubQuery, userSignInQuery } from 'api/users';
import styles from './User.module.scss';
import { message } from 'antd';

const User = () => {
    const { userId } = useParams();
    const { getUserData } = useActions();
    const navigate = useNavigate();
    const { userData } = useTypedSelector((state) => state.UserInformation);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const signInUserAccountHandler = () => {
        userId &&
            userSignInQuery(userId).then((data) => {
                setCookie('token', JSON.stringify(data.token));
                window.location.replace(`${process.env.REACT_APP_BASE_DOMAIN}/admin`);
            });
    };

    const syncUserSub = () => {
        userId &&
        syncUserSubQuery(userId).then((data) => {
            message.success(data.sync)
            }).catch((err)=> {
                message.error(err.message)
            });
    };
    const modalHandler = () => {
        setIsModalOpened(!isModalOpened);
    };
    const deleteUserHandler = () => {
        deleteUserQuery([userData.userId]).then(() => {
            navigate('/users');
        });
    };
    useEffect(() => {
        userId && getUserData(userId);
    }, []);

    if (!userData) {
        return null;
    }
    return (
        <div className={styles.container}>
            <div className={styles.userWrapper}>
                <div className={styles.userInfo}>
                    <div className={styles.imageContainer}>
                        {userData.profileImg ? (
                            <img src={userData.profileImg} alt="avatar" />
                        ) : (
                            <img src={NoAvatarSvg} alt="no-avatar" />
                        )}
                    </div>
                    <div className={styles.userCredentials}>
                        <p className={styles.name}>{userData.fullName}</p>
                        <p className={styles.handle}>{userData.username && `@${userData.username}`}</p>
                    </div>
                </div>
                <div className={styles.userActions}>
                    <div className={styles.deleteBtn}>
                        <Button onClick={modalHandler} Icon={DeleteSvg} backgroundColor={whiteColor} />
                    </div>
                    <div className={styles.secondDivider}></div>
                    <div className={styles.singInBtn}>
                        <Button onClick={syncUserSub} children="Sync Subscription" />
                    </div>
                    <div className={styles.singInBtn}>
                        <Button onClick={signInUserAccountHandler} children="Sign in to account" />
                    </div>
                </div>
            </div>
            {isModalOpened && (
                <Modal onConfirm={modalHandler}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title}>Delete User</h2>
                        <p className={styles.subtitle}>Are you sure you want to delete this user?</p>
                        <div className={styles.buttons}>
                            <div className={styles.cancelBtnContainer} onClick={modalHandler}>
                                <Button backgroundColor={whiteColor} color={blackColor} children="Cancel" />
                            </div>
                            <div className={styles.removeBtnContainer} onClick={deleteUserHandler}>
                                <Button backgroundColor={redColor} color={whiteColor} children="Delete" />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <nav className={styles.navContainer}>
                <ul>
                    {subNavLinks.map((link) => (
                        <CustomLink key={link.id} to={link.route}>
                            {link.navLink}
                        </CustomLink>
                    ))}
                </ul>
            </nav>
            <div className={styles.divider}>
                <Divider />
            </div>
            <Outlet />
        </div>
    );
};

export default User;
