export const getBaseUrlFromEnv = () => {
    if (!process.env.REACT_APP_BASE_DOMAIN) { 
        return 'localhost';
    }

    const hostname = new URL(process.env.REACT_APP_BASE_DOMAIN).hostname;
    const parts = hostname.split('.').reverse();
    if (parts.length >= 2) {
        return parts[1] + '.' + parts[0];
    }

    return hostname;
};

export type CookieOptionsType = {
    path?: string;
    maxAge?: number;
};


export const setCookie = (key: string, value: string, options?: CookieOptionsType): void => {
    const {maxAge, path} = options || {};

    if (!key || !value) {
        return;
    }

    const defaultOptions = {
        path: '/',
        maxAge: 1000 * 60 * 60 * 24 * 7
    };    

    document.cookie = `${key}=${value}; maxAge=${maxAge ? maxAge : defaultOptions.maxAge}; path=${
        path ? path : defaultOptions.path
    }; domain=.${getBaseUrlFromEnv()}`;
};