import React from 'react';
import { IForgotPasswordFormValues } from '../ForgotPassword/interface';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import styles from './RecoverMessage.module.scss';

const RecoverMessage = () => {
    const { forgetPasswordEmail } = useTypedSelector((state) => state.userCredentials);

    const { forgetPassword } = useActions();

    const resendHandler = async () => {
        await forgetPassword({ email: forgetPasswordEmail } as IForgotPasswordFormValues);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.message}>
                <p className={styles.text}>If your email address exists in our database, and you haven't requested a
                    password reset in the last
                    30 minutes, you will receive a password recovery link at your email address in a few minutes.</p>
            </div>
            <div className={styles.resendContainer}>
                <p className={styles.text}>Didn’t get an email?</p>
                <button onClick={resendHandler}>Click to resend</button>
            </div>
        </div>
    );
};

export default RecoverMessage;