import { BuildsDetailsProps, BuildProps, ResendBuildsProps} from 'api/types';

export enum BuildsActionTypes {
    FETCHING_BUILDS = 'FETCHING_BUILDS',
    FETCH_ERROR = 'FETCH_ERROR',
    START_LOADING = 'START_LOADING',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    CLEAR_MESSAGE = 'CLEAR_MESSAGE',
    BUILDS_INFORMATION = "BUILDS_INFORMATION",
    RESEND_BUILD = "RESEND_BUILD",
    FETCHING_BUILD = "FETCHING_BUILD"
}

interface FetchData {
    type: BuildsActionTypes.FETCHING_BUILDS;
    payload: { builds: BuildProps[], dist: number };
}


interface BuildsInformation {
    type: BuildsActionTypes.BUILDS_INFORMATION;
    payload: { build: BuildsDetailsProps };
}

interface ResendBuilds {
    type: BuildsActionTypes.RESEND_BUILD;
    payload: ResendBuildsProps;
}

interface ClearErrorMessage {
    type: BuildsActionTypes.CLEAR_MESSAGE;
    payload?: string;
}

interface Success {
    type: BuildsActionTypes.FETCH_SUCCESS;
    payload?: string;
}

interface Loading {
    type: BuildsActionTypes.START_LOADING;
}

interface Error {
    type: BuildsActionTypes.FETCH_ERROR;
    payload?: string;
}



export type BuildsAction =
    | FetchData
    | Error
    | Loading
    | Success
    | ClearErrorMessage
    | ResendBuilds
    | BuildsInformation
