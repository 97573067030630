import React from 'react';
import MainRoutes from "./routes/MainRoutes";


function App() {
  return (
    <div className="App">
      <MainRoutes/>
    </div>
  );
}

export default App;
