import React, { useMemo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { ReactComponent as DownloadSvg } from 'assets/svg/upload.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/svg/threedots.svg';
import { ReactComponent as RedVectorSvg } from 'assets/svg/redVector.svg';
import { ReactComponent as GreenVectorSvg } from 'assets/svg/greenVector.svg';
import CsvButton from 'components/CsvButton/CsvButton';
import LineChart from 'components/LineChart';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { analyticsMenuItems, convertToScatterData } from 'helpers';
import Divider from 'components/UI/Divider';
import Calendar from 'components/UI/Calendar';
import AnalyticsMonthCalendar from 'components/AnalyticsMonthCalendar';
import AnalyticsYearCalendar from 'components/AnalyticsYearCalendar/AnalyticsYearCalendar';
import { getAnalyticsMonthlyQuery } from 'api/analytics';
import styles from './Analytics.module.scss';
import PieChart from 'components/PieChart';
import StackedBarChart from 'components/StackedBarChart';
import ScatterPlot from 'components/ScatterPlot';
const Analytics = () => {
    const { getAnalyticsData } = useActions();
    const { search } = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(search);
    const range = searchParams.get('range');
    const start = searchParams.get('start');
    const end = searchParams.get('end');
    const { analytics } = useTypedSelector((state) => state.analytics);

    const [startDate, setStartDate] = useState<Date | null>(start === null ? start : dayjs(start).toDate());
    const [endDate, setEndDate] = useState<Date | null>(end === null ? null : dayjs(end).toDate());
    const [isFirstClick, setIsFirstClick] = useState<boolean>(false);

    const onChangeHandler = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        const formatedStart = dayjs(start).format('YYYY-MM-DD');
        const formatedEnd = dayjs(end).format('YYYY-MM-DD');

        if (range === 'daily') {
            setStartDate(start);
            setEndDate(end);
            if (!end) return;
            navigate(`/analytics?range=${range!}&start=${formatedStart}&end=${formatedEnd}`);
        } else if (range === 'monthly' || range === 'yearly') {
            setStartDate(start);
            setEndDate(end);
            if (!end) return;
            navigate(`/analytics?range=${range!}&start=${formatedStart}&end=${formatedEnd}`);
        } else if (range === 'weekly') {
            if (isFirstClick) {
                const condition = 0 > dayjs(start).diff(endDate);
                setIsFirstClick(false);
                setEndDate((prev) => (!condition ? dayjs(formatedStart).weekday(7).toDate() : prev));
                setStartDate((prev) => (condition ? dayjs(formatedStart).weekday(0).toDate() : prev));
                if (condition) {
                    navigate(
                        `/analytics?range=${range!}&start=${dayjs(dayjs(start).weekday(0).toDate()).format(
                            'YYYY-MM-DD',
                        )}&end=${dayjs(endDate).format('YYYY-MM-DD')}`,
                    );
                } else {
                    navigate(
                        `/analytics?range=${range!}&start=${dayjs(startDate).format('YYYY-MM-DD')}&end=${dayjs(
                            dayjs(start).weekday(7).toDate(),
                        ).format('YYYY-MM-DD')}`,
                    );
                }
            } else {
                setStartDate(dayjs(formatedStart).weekday(0).toDate());
                setEndDate(dayjs(formatedStart).weekday(7).toDate());
                setIsFirstClick(true);
            }
        }
    };

    const analyticsCsvData = useMemo(() => {
        if (!analytics) return;
        const newData = [];
        const months = Object.keys(analytics.signUps);

        months.forEach((month) => {
            newData.push({
                Months: month,
                Auth: analytics.signUps[month],
                Digests: analytics.usersWithDigests[month],
                Opens: analytics.opens[month],
                Clicks: analytics.clicks[month],
                Sends: analytics.sends[month],
                Rejects: analytics.rejects[month],
                Deliveries: analytics.deliveries[month],
                Complaints: analytics.complaints[month],
            });
        });

        const total = {
            Months: 'Total',
            Auth: Object.values(analytics.signUps).reduce((a, b) => Number(a) + Number(b), 0),
            Digests: Object.values(analytics.usersWithDigests).reduce((a, b) => Number(a) + Number(b), 0),
            Opens: Object.values(analytics.opens).reduce((a, b) => Number(a) + Number(b), 0),
            Clicks: Object.values(analytics.clicks).reduce((a, b) => Number(a) + Number(b), 0),
            Sends: Object.values(analytics.sends).reduce((a, b) => Number(a) + Number(b), 0),
            Rejects: Object.values(analytics.rejects).reduce((a, b) => Number(a) + Number(b), 0),
            Deliveries: Object.values(analytics.deliveries).reduce((a, b) => Number(a) + Number(b), 0),
            Complaints: Object.values(analytics.complaints).reduce((a, b) => Number(a) + Number(b), 0),
        };

        newData.push(total);
        return newData;
    }, [analytics]);

    useEffect(() => {
        if (!start && !end) {
            setStartDate(null);
            setEndDate(null);
        }

        getAnalyticsData({ range, start, end });
    }, [range, start, end]);

    function calculateSum(obj: any, key1: string): number {
        return Object.values(obj?.[key1]).reduce((prev: number, curr: any) => Number(prev) + Number(curr), 0);
    }

    function calculateAverage(obj: any, key1: string): string {
        const values = Object.values(obj?.[key1])
            .map((value: any) => value !== null ? Number(value) : null)
            .filter((value: number | null) => value !== null) as number[];
    
        const sum = values.reduce((prev: number, curr: number) => prev + curr, 0);
        return values.length ? (sum / values.length).toFixed(2) : '0';
    }

    const authSum = calculateSum(analytics, 'signUps');
    const digestsSum = calculateSum(analytics, 'usersWithDigests');
    const opensSum = calculateSum(analytics, 'opens');
    const clicksSum = analytics && calculateSum(analytics, 'clicks');
    const sendsSum = calculateSum(analytics, 'sends');
    const deliveriesSum = calculateSum(analytics, 'deliveries');
    const rejectsSum = calculateSum(analytics, 'rejects');
    const complaintsSum = calculateSum(analytics, 'complaints');
    const buildsFailedSum = calculateSum(analytics, 'buildsFailed');
    const buildsSuccessfulSum = calculateSum(analytics, 'buildsSuccessful');
    const buildsProcessingSum = calculateSum(analytics, 'buildsProcessing');
    const totalBuilds = calculateSum(analytics, 'digestBuilds');
    const emailCompletionAverage = calculateAverage(analytics, 'emailCompletion');
    const averageBuildTimes = calculateSum(analytics, 'avgBuildDurationInseconds');
    const unloggedBuildSum = totalBuilds - (buildsProcessingSum + buildsFailedSum + buildsSuccessfulSum);

    const chartRenderingData = {
        buildsFailed: buildsFailedSum,
        buildsSuccessful: buildsSuccessfulSum,
        buildsProcessing: buildsProcessingSum,
        unloggedBuildSum: unloggedBuildSum
    }


    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.titles}>
                    <h1 className={styles.mainTitle}>Overview</h1>
                    <p className={styles.titleSubtext}>View data about all users that registered.</p>
                </div>
                <nav>
                    <ul className={styles.tabsContainer}>
                        {analyticsMenuItems.map((item, index) => (
                            <li
                                key={index}
                                className={`${styles.tabBtn} ${
                                    range === item.name || (!range && item.name === 'daily') ? styles.active : ''
                                }`}
                            >
                                <Link to={{ search: `?range=${item.name}` }}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className={styles.buttonsContainer}>
                    <div className={styles.downloadBtn}>
                        <CsvButton data={analyticsCsvData}>
                            {' '}
                            <DownloadSvg /> Download CSV
                        </CsvButton>
                    </div>
                </div>
                <nav>
                    <ul className={styles.mobileTabContainer}>
                        {analyticsMenuItems.map((item, index) => (
                            <li
                                key={index}
                                className={
                                    range === item.name || (!range && item.name === 'daily') ? styles.active : ''
                                }
                            >
                                <Link to={{ search: `?range=${item.name}` }}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className={styles.mobileDivider}>
                    <Divider />
                </div>
            </div>
            <div className={styles.calendarContainer}>
                <div className={styles.calendarWrapper}>
                    {(range === 'daily' || range === 'weekly') && (
                        <Calendar startDate={startDate} onChange={onChangeHandler} endDate={endDate} isMaxDate={true} />
                    )}
                    {range === 'monthly' && (
                        <AnalyticsMonthCalendar startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    )}
                    {range === 'yearly' && (
                        <AnalyticsYearCalendar startDate={startDate} onChange={onChangeHandler} endDate={endDate} />
                    )}
                </div>
            </div>
            <div className={`${styles.chartContainer} ${styles.space}`}>
                <div className={styles.chartHeader}>
                    <h2>Total registered users</h2>
                    <ThreeDotsSvg />
                </div>
                <div className={styles.chartWrapper}>
                    <div className={styles.chartInfo1}>
                        <div className={styles.singUpInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Sign Ups</span>
                            </div>
                            <span className={styles.number}>{authSum}</span>
                            {range === 'monthly' || range === 'yearly' ? (
                                <div className={styles.percent}>
                                    <RedVectorSvg />
                                    <span className={styles.percentageNumber}>11,2%</span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={styles.createdAtInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Created First Digest</span>
                            </div>
                            <span className={styles.number}>{digestsSum}</span>
                            {range === 'monthly' || range === 'yearly' ? (
                                <div className={styles.percent}>
                                    <GreenVectorSvg />
                                    <span className={styles.percentageNumber}>11,6%</span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className={styles.charts}>
                        <LineChart
                            chartFirstInfoData={analytics?.signUps}
                            chartSecondInfoData={analytics?.usersWithDigests}
                            name = "Onboarding Insights"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.chartContainer}>
                <div className={styles.chartHeader}>
                    <h2>Email interactions</h2>
                    <ThreeDotsSvg />
                </div>
                <div className={styles.chartWrapper}>
                    <div className={styles.chartInfo2}>
                        <div className={styles.singUpInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Opens</span>
                            </div>
                            <span className={styles.number}>{opensSum}</span>
                            {/* {range === 'monthly' || range === 'yearly' ? (
                                <div className={styles.percent}>
                                    <RedVectorSvg />
                                    <span className={styles.percentageNumber}>11,2%</span>
                                </div>
                            ) : (
                                ''
                            )} */}
                        </div>

                        <div className={styles.createdAtInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Clicks</span>
                            </div>
                            <span className={styles.number}>{clicksSum}</span>
                        </div>

                        <div className={styles.sendsInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Sends</span>
                            </div>
                            <span className={styles.number}>{sendsSum}</span>
                        </div>

                        <div className={styles.rejectsInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Rejects</span>
                            </div>
                            <span className={styles.number}>{rejectsSum}</span>
                        </div>

                        <div className={styles.deliveriesInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Deliveries</span>
                            </div>
                            <span className={styles.number}>{deliveriesSum}</span>
                        </div>

                        <div className={styles.complaintsInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Complaints</span>
                            </div>
                            <span className={styles.number}>{complaintsSum}</span>
                        </div>
                    </div>
                    <div className={styles.charts}>
                        <LineChart
                            chartFirstInfoData={analytics?.opens}
                            chartSecondInfoData={analytics?.clicks}
                            chartThirdInfoData={analytics?.sends}
                            chartFourthInfoData={analytics?.rejects}
                            chartFifthInfoData={analytics?.deliveries}
                            chartSixthInfoData={analytics?.complaints}
                            name = "Email Insights"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.chartContainer}>
                <div className={styles.chartHeader}>
                    <h2>Digest Builds</h2>
                    <ThreeDotsSvg />
                </div>
                <div className={styles.buildChartWrapper}>
                    <div className={styles.chartInfo2}>
                        <div className={styles.buildsSuccessfulInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Successful</span>
                            </div>
                            <span className={styles.number}>{buildsSuccessfulSum}</span>
                        </div>

                        <div className={styles.buildsFailedInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Failed</span>
                            </div>
                            <span className={styles.number}>{buildsFailedSum}</span>
                        </div>

                        <div className={styles.buildsProcessingInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Processing</span>
                            </div>
                            <span className={styles.number}>{buildsProcessingSum}</span>
                        </div>

                        <div className={styles.unloggedBuildContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Unlogged Jobs</span>
                            </div>
                            <span className={styles.number}>{unloggedBuildSum}</span>
                        </div>

                        <div className={styles.emailCompletionInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>All Emails Sent</span>
                            </div>
                            <span className={styles.number}>{emailCompletionAverage}%</span>
                        </div>

                        <div className={styles.averageBuildTimesInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Average Build Duration</span>
                            </div>
                            <span className={styles.number}>{ averageBuildTimes.toFixed(2)}s</span>
                        </div>

                        <div className={styles.totalBuildInformationContent}>
                            <div className={styles.info}>
                                <span className={styles.circle}></span>
                                <span className={styles.title}>Total Builds</span>
                            </div>
                            <span className={styles.number}>{totalBuilds}</span>
                        </div>
                    </div>
                    <div className={styles.charts}>
                    <div className={styles.chart}>
                        <PieChart
                            chartRenderingData={chartRenderingData}
                        />
                    </div>
                    <div className={styles.chart}>
                    <LineChart
                            chartFirstInfoData={analytics?.buildTrend}
                            name = "Build Uptrend"
                        />
                    </div>
                    <div className={styles.chart}>
                    {analytics?.sourceBuildAnalytics && <StackedBarChart
                            data={analytics?.sourceBuildAnalytics}
                        />}
                    </div>
                    <div className={styles.chart}>
                    {analytics?.digestAnalytics && <ScatterPlot
                            data={convertToScatterData(analytics.digestAnalytics.data)}
                        />}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Analytics;
