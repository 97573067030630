import { BuildsDetailsProps } from 'api/types';
import {BuildsActionTypes, BuildsAction} from 'store/types/buildsActionTypes';

export interface BuildProps {
    emailsSent: string[];
    emailsExpected: string[];
    buildId: string;
    buildNumber: number;
    buildEmail: string;
    buildWeb: string;
    buildShare: string | null;
    createdAt: string;
    updatedAt: string;
    userEmail: string;
  }

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    dist: number,
    buildData: BuildProps[]
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    dist: 0,
    buildData: []
};


export const builds = (state = initialState, action: BuildsAction): StateTyp => {
    switch (action.type) {
        case BuildsActionTypes.FETCHING_BUILDS:
            return {
                ...state,
                isLoading: true,
                dist: action.payload.dist,
                buildData: [...action.payload.builds],
            };
    
        default:
            return state;
    }
};