import React from 'react';
import styles from './TwitterSearchSourceCard.module.scss';

export type TwitterSearchSourceDto = {
    hideRetweets: boolean;
    hideReplies: boolean;
    linksOnly: boolean;
    imagesOnly: boolean;
    search: string;
}

interface TwitterSearchSourceCardProps {
    source: TwitterSearchSourceDto;
}

const TwitterSearchSourceCard: React.FC<TwitterSearchSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.twitterCard}>
            <h2>Twitter Search</h2>
            <p>Search Query: {source.search}</p>
            <p>Hide Retweets: {source.hideRetweets ? 'Yes' : 'No'}</p>
            <p>Hide Replies: {source.hideReplies ? 'Yes' : 'No'}</p>
            <p>Links Only: {source.linksOnly ? 'Yes' : 'No'}</p>
            <p>Images Only: {source.imagesOnly ? 'Yes' : 'No'}</p>
        </div>
    );
};

export default TwitterSearchSourceCard;
