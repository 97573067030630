import {AppDispatch} from 'store';
import {apiQuery} from 'helpers/query';
import { BuildsActionTypes } from 'store/types/buildsActionTypes';
import { getBuildsQuery, getSourceHealthQuery,  } from 'api/builds';
import { UserActionTypes } from 'store/types/userActionTypes';


export interface IGetBuildsDataParams {
    searchParams: string | null,
    limit: string | null,
    sort: string | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    page: string | null,
}


export const getBuildsData = ({searchParams, sort, page, dateFrom, dateTo, limit}: IGetBuildsDataParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const {builds, dist} = await apiQuery(() => getBuildsQuery({
                searchParams,
                page,
                sort,
                limit,
                dateFrom,
                dateTo
            }));

            dispatch({
                type: BuildsActionTypes.FETCHING_BUILDS,
                payload: {builds, dist},
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: BuildsActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const getSourceHeathStatus = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await apiQuery(() => getSourceHealthQuery());

            dispatch({
                type: UserActionTypes.FETCHING_SOURCE_HEALTH,
                payload: data,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: BuildsActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};


