import {AppDispatch} from 'store';
import {apiQuery} from 'helpers/query';
import {BuildsActionTypes } from 'store/types/buildsActionTypes';
import { getBuildQuery, resendBuildQuery } from 'api/builds';


export interface IGetBuildDataParams {
    buildId: string
}


export const getBuildData = ({ buildId }: IGetBuildDataParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const buildsDetails = await apiQuery(() => getBuildQuery(buildId));

            dispatch({
                type: BuildsActionTypes.BUILDS_INFORMATION,
                payload: buildsDetails,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: BuildsActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};


// export const resendBuild = ({ buildId }: IGetBuildDataParams) => {
//     return async (dispatch: AppDispatch) => {
//         try {
//             const resentBuildDetails = await apiQuery(() => resendBuildQuery(buildId));

//             dispatch({
//                 type: BuildsActionTypes.RESEND_BUILD,
//                 payload: resentBuildDetails,
//             });
//         } catch (error) {
//             if (error instanceof Error) {
//                 dispatch({
//                     type: BuildsActionTypes.FETCH_ERROR,
//                     payload: error.message,
//                 });
//             }
//         }
//     };
// };



