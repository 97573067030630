import { BuildsDetailsProps } from 'api/types';
import {BuildsActionTypes, BuildsAction} from 'store/types/buildsActionTypes';

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    dist: number,
    buildData: BuildsDetailsProps
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    dist: 0,
    buildData: {
        emailsSent: [],
        emailsExpected: [],
        buildId: '',
        buildNumber: 0,
        buildEmail: '',
        buildWeb: '',
        createdAt: '',
        updatedAt: '',
        digest: {
            digestId: '',
            name: '',
            time: '',
            active: false,
            isPublic: false,
            scheduleType: '',
            sources: [{
                name: "",
                icon: "",
                type: ""
            }],
            createdAt: '',
            updatedAt: '',
            user: {
                userId: '',
                email: '',
                userEmail: '',
                userProfileImg: {
                    key: '',
                    bucket: '',
                    location: ''
                }
            },
            builds: [],
            subscribers: []
        },
        buildLogs: [],
        emailLogs: [],
        sourceBuildLogs: []
    }
};


export const buildInformation = (state = initialState, action: BuildsAction): StateTyp => {
    switch (action.type) {
        case BuildsActionTypes.BUILDS_INFORMATION:
            return {
                ...state,
                isLoading: true,
                buildData: {...action.payload.build},
            };
    
        default:
            return state;
    }
};