import React, {useState, useEffect, FC} from 'react';
import {ReactComponent as DropDownSvg} from "assets/svg/dropwDown.svg";
import {ReactComponent as CheckSvg} from "assets/svg/check.svg";
import {useClickOutside} from 'hooks/useClickOutside';
import styles from "./PageSizer.module.scss";


export interface ISelect {
    currentItem: string;
    onClick: (tz: string) => void;
    array: string[];
}

const PageSizer: FC<ISelect> = ({currentItem, onClick, array}) => {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState('');
    const handler = () => {
        setOpen((prev) => !prev);
    };
    useEffect(() => {
        setCurrent(currentItem);

    }, [currentItem]);

    const domNode = useClickOutside(() => {
        setOpen(false);
    });
    return (
        <div className={styles.container} ref={domNode}>
            <div className={`${styles.selectBtn} ${open ? styles.active : ''}`} onClick={handler}>
                <div className={styles.clockSvg}>
                    <span className={styles.btnText}>{current}</span>
                </div>
                <div className={styles.arrowDown}>
                    <DropDownSvg/>
                </div>
            </div>

            <div className={styles.listItems}>
                {array.map((item, index) => {
                    return (
                        <li className={`${styles.item} ${item === currentItem ? styles.checked : ''}`}
                            key={item + index}
                            onClick={() => {
                                setTimeout(() => {
                                    onClick(item);
                                    setCurrent(item);
                                    handler();
                                }, 165);
                            }}>
                            <span className={styles.itemText}>{item}</span>
                            <span className={styles.checkMark}>
                                <CheckSvg/>
                            </span>
                        </li>
                    );
                })}
            </div>
        </div>
    );
}

export default PageSizer;