import { DigestsDetailsProps, DigestsProps} from 'api/types';

export enum DigestsActionTypes {
    FETCHING_DIGESTS = 'FETCHING_DIGESTS',
    FETCHING_DIGEST = 'FETCHING_DIGEST',
    FETCH_ERROR = 'FETCH_ERROR',
    START_LOADING = 'START_LOADING',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    CLEAR_MESSAGE = 'CLEAR_MESSAGE',
    DIGESTS_INFORMATION = "DIGESTS_INFORMATION"
}

interface FetchData {
    type: DigestsActionTypes.FETCHING_DIGESTS;
    payload: { digests: DigestsProps[], dist: number };
}


interface DigestsInformation {
    type: DigestsActionTypes.DIGESTS_INFORMATION;
    payload: DigestsDetailsProps;
}


interface ClearErrorMessage {
    type: DigestsActionTypes.CLEAR_MESSAGE;
    payload?: string;
}

interface Success {
    type: DigestsActionTypes.FETCH_SUCCESS;
    payload?: string;
}

interface Loading {
    type: DigestsActionTypes.START_LOADING;
}

interface Error {
    type: DigestsActionTypes.FETCH_ERROR;
    payload?: string;
}



export type DigestsAction =
    | FetchData
    | Error
    | Loading
    | Success
    | ClearErrorMessage
    | DigestsInformation
