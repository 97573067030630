import * as React from 'react';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ScriptableContext,
    Tooltip,
    Legend,
    Filler,
    registerables as registerablesJS,
} from 'chart.js';
import dayjs from 'dayjs';

ChartJS.register(...registerablesJS);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

let options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            align: 'start' as const,
            position: 'top' as const,
            text: 'Metrics Chart',
            color: '#000000',
        },
    },
    scales: {
        x: {
            grid: {
                drawBorder: false,
                lineWidth: 0,
            },
        },
    },
    maintainAspectRatio: false,
};

const LineChart = React.memo(
    ({
        chartFirstInfoData = {},
        chartSecondInfoData = {},
        chartThirdInfoData = {},
        chartFourthInfoData = {},
        chartFifthInfoData = {},
        chartSixthInfoData = {},
        name = "Metrics Chart"
    }: any) => {
        if (name)  options.plugins.title.text = name
        const chartData = () => {
            return {
                datasets: [
                    {
                        borderColor: '#F6C035',
                        fill: true,
                        data: Object.entries(chartFirstInfoData),
                            // .sort((a, b) => dayjs(a[0]).diff(b[0])),
                        lineTension: 0.2,
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(255, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(250, 174,  50, 0)');
                            return gradient;
                        },
                    },
                    {
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(55, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(50,174,50,0)');
                            return gradient;
                        },
                        borderColor: 'rgba(159, 169, 164, 1)',
                        fill: true,
                        data: Object.entries(chartSecondInfoData),
                        lineTension: 0.2,
                    },
                    {
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(155, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(150,174,50,0)');
                            return gradient;
                        },
                        borderColor: '#12B76A',
                        fill: true,
                        data: Object.entries(chartThirdInfoData),
                        lineTension: 0.2,
                    },
                    {
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(55, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(50,174,50,0)');
                            return gradient;
                        },
                        borderColor: '#B42318',
                        fill: true,
                        data: Object.entries(chartFourthInfoData),
                        lineTension: 0.2,
                    },
                    {
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(55, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(50, 174,  50, 0)');
                            return gradient;
                        },
                        borderColor: '#0911b4',
                        fill: true,
                        data: Object.entries(chartFifthInfoData),
                        lineTension: 0.2,
                    },
                    {
                        backgroundColor: (context: ScriptableContext<any>) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                            gradient.addColorStop(0, 'rgba(55, 236, 188, 1)');
                            gradient.addColorStop(1, 'rgba(50,174,50,0)');
                            return gradient;
                        },
                        borderColor: '#F97066',
                        fill: true,
                        data: Object.entries(chartSixthInfoData),
                        lineTension: 0.2,
                    },
                ],
            };
        };

        return <Line data={chartData()} options={options} />;
    },
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);
export default LineChart;
