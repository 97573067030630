import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomBtn from '../CustomBtn';

export interface ICalendar {
    onChange: (dates: [Date | null, Date | null]) => void;
    endDate: Date | null | undefined;
    startDate: Date | null | undefined;
    isMaxDate?: boolean;
}

const Calendar: FC<ICalendar> = ({ onChange, endDate, startDate, isMaxDate }) => {
    return (
        <DatePicker
            selected={endDate}
            onChange={onChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            maxDate={isMaxDate ? new Date(new Date().setDate(new Date().getDate() - 1)) : null}
            monthsShown={2}
            customInput={React.createElement(CustomBtn)}
        />
    );
};
export default Calendar;
