import { uploadRSSLinksQuery } from 'api/users';
import { apiQuery } from 'helpers/query';
import { HealthReport } from 'pages/Sources/health-report-types';
import { Dispatch } from 'redux';
import {UserAction, UserActionTypes} from 'store/types/userActionTypes';

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    sourcesData: any[]
    dist: number
}

interface SourceHealthStateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    sourcesHealthReport: HealthReport[]
}

const initialSourceHealthState: SourceHealthStateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    sourcesHealthReport: [],
};

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    sourcesData: [],
    dist: 0
};


export const sources = (state = initialState, action: UserAction): StateTyp => {
    switch (action.type) {
        case UserActionTypes.FETCHING_SOURCES:
            return {
                ...state,
                isLoading: true,
                dist: action.payload.dist,
                sourcesData: [...action.payload.sources],
            };

        case UserActionTypes.FETCHING_SOURCE_HEALTH:
            return {
                ...state,
                isLoading: true,
                sourcesData: [...action.payload],
            };

        default:
            return state;
    }
};

export const sourcesHealth = (state = initialSourceHealthState, action: UserAction): SourceHealthStateTyp => {
    switch (action.type) {
        case UserActionTypes.FETCHING_SOURCE_HEALTH:
            return {
                ...state,
                isLoading: true,
                sourcesHealthReport: [...action.payload],
            };

        default:
            return state;
    }
};


