import {Dispatch} from 'redux';

import {UserAction, UserActionTypes} from 'store/types/userActionTypes';
import {removeState, saveState} from 'helpers/LocalStorage';
import {ILoginFormValues} from 'pages/LogIn/interface';
import {
    forgetPasswordQuery,
} from 'api/authService';
import {IForgotPasswordFormValues} from 'pages/ForgotPassword/interface';
import {apiQuery} from 'helpers/query';
import {loginQuery, logoutQuery} from 'api/loginService';


export const login = ({email, password}: ILoginFormValues) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({
                type: UserActionTypes.START_LOADING,
            });
            const data = await loginQuery({email, password});

            saveState('admin_token', {accessToken: data.token.accessToken, refreshToken: data.token.refreshToken});

            dispatch({
                type: UserActionTypes.USER_LOGIN,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }

        }
    };
};


export const logout = () => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({
                type: UserActionTypes.START_LOADING,
            });
            await apiQuery(logoutQuery);

            removeState('admin_token');
            dispatch({
                type: UserActionTypes.USER_LOGOUT,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }

    };
};

export const forgetPassword = (data: IForgotPasswordFormValues) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            await forgetPasswordQuery(data);
            dispatch({
                type: UserActionTypes.USER_FORGET_PASSWORD,
                payload: data.email,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const setError = (errorMessage: string) => {
    return (dispatch: Dispatch<UserAction>) => {
        dispatch({
            type: UserActionTypes.FETCH_ERROR,
            payload: errorMessage,
        });
    };
};

export const setSuccess = (successMessage: string) => {
    return (dispatch: Dispatch<UserAction>) => {
        dispatch({
            type: UserActionTypes.FETCH_SUCCESS,
            payload: successMessage,
        });
    };
};

export const clearError = () => {
    return async (dispatch: Dispatch<UserAction>) => {
        dispatch({
            type: UserActionTypes.CLEAR_MESSAGE,
        });
    };
};






