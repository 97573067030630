export enum AnalyticsActionTypes {
    FETCHING_ANALYTICS_DATA = 'FETCHING_ANALYTICS_DATA',
    FETCH_ERROR = 'FETCH_ERROR',
}

interface FetchAnalyticsData {
    type: AnalyticsActionTypes.FETCHING_ANALYTICS_DATA;
    payload: any;
}

interface Error {
    type: AnalyticsActionTypes.FETCH_ERROR;
    payload?: string;
}

export type AnalyticsAction = FetchAnalyticsData | Error