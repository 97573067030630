import axios, {errorCallBack} from './axios';
import { DigestsDetailsProps, DigestsProps } from './types';

interface IParams {
    searchParams: string | null,
    limit: string | null,
    sort: string | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    page: string | null,
}

export const getDigestsQuery = ({
                                  searchParams,
                                  sort,
                                  dateFrom,
                                  dateTo,
                                  limit,
                                  page,
                              }: IParams): Promise<{ digests: DigestsProps[], dist: number }> => {
    return axios.get(`/admin/digests?${searchParams ? `search=${searchParams}&` : ""}${sort ? `sort=${sort}&` : ""}${dateFrom ? `from=${dateFrom}&` : ""}${dateTo ? `to=${dateTo}&` : ""}${limit ? `limit=${limit}&` : ""}${page ? `page=${page}&` : ""}`).then(({ data }) => {
        return data
    }).catch(errorCallBack);
};

export const getDigest= (digestId: string): Promise<DigestsDetailsProps> => {
    return axios.get(`/admin/digest/${digestId}`).then(({data}) => data).catch(errorCallBack);
};