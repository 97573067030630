import {DigestsActionTypes, DigestsAction} from 'store/types/digestsActionTypes';

export interface BuildLogProps {
    logId: string,
    status: string,
    description: string,
    completion_value: object | null,
    createdAt: string,
    digest_id: string,
    build_id: string
}

interface StateTyp {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string,
    dist: number,
    digestsData: any[],
    type: string
}

const initialState: StateTyp = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    dist: 0,
    digestsData: [],
    type: ''
};


export const digests = (state = initialState, action: DigestsAction): StateTyp => {
    switch (action.type) {
        case DigestsActionTypes.FETCHING_DIGESTS:
            return {
                ...state,
                isLoading: true,
                dist: action.payload.dist,
                digestsData: [...action.payload.digests],
            };
        default:
            return state;
    }
};