import {Dispatch} from 'redux';
import {AppDispatch} from 'store';
import {apiQuery} from 'helpers/query';
import {UserActionTypes, UserAction} from 'store/types/userActionTypes';
import {getUserQuery, userChangePhotoQuery, userEditQuery} from 'api/users';
import {UserEditProfileProps} from 'api/types';


export const getUserData = (userId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await apiQuery(() => getUserQuery(userId));
            dispatch({
                type: UserActionTypes.SET_USER_DATA,
                payload: data,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const editUserData = (userId: string, userData: UserEditProfileProps) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            const {user} = await apiQuery(() => userEditQuery(userData, userId));
            dispatch({
                type: UserActionTypes.SET_USER_DATA,
                payload: user,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};

export const addUserPhoto = (file: File, userId: string) => {
    return async (dispatch: Dispatch<UserAction>) => {
        dispatch({
            type: UserActionTypes.START_EDIT_LOADING,
        });
        try {
            const {user} = await apiQuery(() => userChangePhotoQuery(file, userId));
            dispatch({
                type: UserActionTypes.SET_USER_DATA,
                payload: user,
            });
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: UserActionTypes.FETCH_ERROR,
                    payload: error.message,
                });
            }
        }
    };
};