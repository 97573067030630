export const fetchQuery = (query: () => void) => {
    let interval = setTimeout(() => {
        query();
    }, 0);
    return (query: () => void) => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            query();
        }, 1000);
    };
};