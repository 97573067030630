import {debounce} from 'lodash';
import {useEffect, useState} from 'react';

interface IViewport {
    width: number;
    height: number;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
}

export const useViewport = (): IViewport => {
    const [viewport, setViewport] = useState<IViewport>({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < 568,
        isTablet: window.innerWidth >= 568 && window.innerWidth < 992,
        isDesktop: window.innerWidth >= 992
    });

    const handleResize = () => {
        setViewport({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth < 568,
            isTablet: window.innerWidth >= 568 && window.innerWidth < 992,
            isDesktop: window.innerWidth >= 992
        });
    };

    useEffect(() => {
        window.addEventListener('resize', debounce(handleResize, 100));
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return viewport;
};