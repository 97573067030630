import React from 'react';
import styles from './RSSSourceCard.module.scss'; 

export type RSSSourceDto = {
    showHeading: boolean;
    name?: string;
    numberOfPosts: number;
    layout: 1 | 2;
    sourceName: string;
    favicon: string;
    feedLink: string;
    images: 'show' | 'hide';
    postBody: 'paragraph' | 'other'; 
}

interface RSSSourceCardProps {
    source: RSSSourceDto; 
}

const RSSSourceCard: React.FC<RSSSourceCardProps> = ({ source }) => {
    return (
        <div className={styles.rssCard}>
            <h2>{source.name || 'RSS Feed'}</h2>
            {source.showHeading && <h3>{source.sourceName}</h3>}
            <p>Number of Posts: {source.numberOfPosts}</p>
            <p>Layout: {source.layout === 1 ? 'Layout 1' : 'Layout 2'}</p>
            <p>Feed Link: <a href={source.feedLink}>{source.feedLink}</a></p>
            <p>Favicon: <img src={source.favicon} alt="Favicon" /></p>
            <p>Images: {source.images}</p>
            <p>Post Body: {source.postBody}</p>
        </div>
    );
};

export default RSSSourceCard;
